import React, { forwardRef, useState, useImperativeHandle, useEffect } from "react"
import { setPreferencesApi } from '@scripts/main.js'
import useStore from '@stores'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import validators from '../validators'

export default forwardRef((props, ref) => {
  const user = useStore(state => state.user)
  const preferences = useStore(state => state.preferences)
  const setPreferences = useStore(state => state.setPreferences)
  const trainedLabels = useStore(state => state.trainedLabels)
  const addLog = useStore(state => state.addLog)
  const [show, setShow] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [params, setParams] = useState({})
  const [messages, setMessages] = useState([])
  // const modelName = ['model1', 'model2', 'model3', 'model4']
  const modelNames = Object.keys(trainedLabels).sort() // 取得全部模型名稱
  const minScore = 0, maxScore = 1
  const minIou = 0, maxIou = 1
  const minTopk = 1, maxTopk = 100
  
  useImperativeHandle(ref, () => ({
    setShow(bol) {
      setShow(bol)
    }
  }))

  useEffect(() => {
    if (show) {
      setParams(preferences.predictConfig)
    } else {
      reset()
      setMessages([])
    }
  }, [show])

  const reset = () => {
    setParams(preferences.predictConfig)
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    preferences['predictConfig'] = params
    const response = await setPreferencesApi(user.uid, preferences)
    if (response.status==='success') {
      addLog(`儲存設定完成`)
      setPreferences('predictConfig', params)
    } 
    if (response.status==='fail') {
      addLog(`儲存設定失敗！`)
    }
    reset()
    setShow(false)
    setSubmitting(false) 
  }

  const handleOnModelChange = e => {
    const value = e.target.value
    params.model = value
    setParams(params)
    let newMessages = []
    const response = validators['fileNameFormat'](value)
    if(!response.passed) newMessages.push(...response.messages)
    setMessages(newMessages)
  }
  const handleOnScoreChange = e => {
    const value = e.target.value ? parseFloat(e.target.value) : ''
    params.score = value
    setParams(params)
    let newMessages = []
    const response = validators['numberFormat'](value, minScore, maxScore)
    if(!response.passed) newMessages.push(...response.messages)
    setMessages(newMessages)
  }
  const handleOnIouChange = e => {
    const value = e.target.value ? parseFloat(e.target.value) : ''
    params.iou = value
    setParams(params)
    let newMessages = []
    const response = validators['numberFormat'](value, minIou, maxIou)
    if(!response.passed) newMessages.push(...response.messages)
    setMessages(newMessages)
  }
  const handleOnTopkChange = e => {
    const value = e.target.value ? parseInt(e.target.value) : ''
    params.topk = value
    setParams(params)
    let newMessages = []
    const response = validators['numberFormat'](value, minTopk, maxTopk)
    if(!response.passed) newMessages.push(...response.messages)
    setMessages(newMessages)
  }

  return (
    <Modal 
      show={show} 
      onHide={()=>setShow(false)}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      backdrop={!submitting ? true : 'static'}>
      <Modal.Header closeButton={!submitting}>
        <Modal.Title as="h5">辨識參數設定</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={e => { e.preventDefault(); }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>選取模型</Form.Label>
            <Form.Select size="sm" value={params.model} onChange={handleOnModelChange} disabled={submitting}>
              {modelNames.map(m => <option key={`fix-item-${m}`} value={m}>{m}</option>)}
            </Form.Select>
            <Form.Label>預測框分數門檻 (預設0.1)，低於此值的預測框不會顯示</Form.Label>
            <Form.Control 
              type="number" min={minScore} max={maxScore} step="0.1" 
              size="sm" placeholder="請輸入數值" value={params.score} 
              onChange={handleOnScoreChange} disabled={submitting} />
            <Form.Label>預測框重疊比例 (預設0.1)，重疊大於此值的預測框不會顯示</Form.Label>
            <Form.Control 
              type="number" min={minIou} max={maxIou} step="0.1" 
              size="sm" placeholder="請輸入數值" value={params.iou} 
              onChange={handleOnIouChange} disabled={submitting} />
            <Form.Label>預測框數量上限 (預設30)，最多只顯示此數量的預測框</Form.Label>
            <Form.Control 
              type="number" min={minTopk} max={maxTopk} step="1"
              size="sm" placeholder="請輸入數值" value={params.topk} 
              onChange={handleOnTopkChange} disabled={submitting} />
            {messages.map((m, i) => 
              <Form.Text key={i} className="text-danger">{m.content}</Form.Text>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          disabled={params==={} || submitting || messages.filter(m => m.type==='error').length!==0} 
          variant="secondary" size="sm" onClick={handleSubmit}>
          儲存{submitting ? '中...' : ''}
        </Button>
      </Modal.Footer>
    </Modal>
  )
})