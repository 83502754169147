import React, { useEffect, forwardRef, useState, useImperativeHandle, useRef } from "react"
import { addPhotoApi } from '@scripts/main.js'

import useStore from '@stores'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import CloseButton from 'react-bootstrap/CloseButton'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { useDropzone } from 'react-dropzone'

import validators from '../validators'

export default forwardRef((props, ref) => {
  const photosByFolder = useStore(state => state.photosByFolder)
  // const updateLists = useStore(state => state.updateLists)
  const addLog = useStore(state => state.addLog)
  const activeIds = useStore(state => state.activeIds)
  const [show, setShow] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [rewriteSubmitting, setRewriteSubmitting] = useState(false)
  const [hasDuplicate, setHasDuplicate] = useState(false)

  const [folderId, setFolderId] = useState('')
  const [folderOptions, setFolderOptions] = useState(photosByFolder)
  const [files, setFiles] = useState([]);
  const [messages, setMessages] = useState([])

  const photosProgressRef = useRef([])
  
  useImperativeHandle(ref, () => ({
    setShow(bol) {
      setShow(bol)
    },
    getShowState() {
      return show
    },
    addNewFolder(value) {
      setFolderId(value)
      setFolderOptions([{ id: value, photos: [] }, ...folderOptions])
    }
  }))

  useEffect(() => {
    setFolderOptions(photosByFolder)
  }, [photosByFolder])

  useEffect(() => {
    if (show) {
      setFolderId(activeIds.folder || '')
    } else {
      reset()
      setMessages([])
    }
  }, [show])

  const reset = () => {
    setFolderId('')
    setFiles([])
    setHasDuplicate(false)
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    addLog(`[${folderId}] 目錄照片上傳中...`)

    let failFiles = []
    let failMessages = []
    let hasDupli = false
    await Promise.all(files.map(async (file, index) => {
    // for (let index=0; index<files.length; index++) {
      // const file = files[index]
      const photoExists = folderOptions.find(f => f.id === folderId).photos.includes(file.uploadName)
      if (!photoExists) {
        const response = await addPhotoApi(folderId, file, [], false, photosProgressRef.current[file.uploadName])
        if(response.status==='success') {
          // console.log(`[${index} ${file.uploadName}] 上傳成功`)
          addLog(`[${file.uploadName}] 上傳完成`)
          // addPhoto(folderId, file.uploadName)
        } 
        if(response.status==='fail') {
          // console.log(`[${index} ${file.uploadName}] 上傳失敗！`)
          addLog(`[${file.uploadName}] 上傳失敗！`)
          failMessages.push(response.messages)
          failFiles.push(Object.assign(file, {
            preview: URL.createObjectURL(file),
            uploadName: file.uploadName
          }))
          // if(response.messages[0].name === 'DUPLICATE') hasDupli = true
        }
      } else {
        addLog(`[${file.uploadName}] 上傳失敗！`)
          failMessages.push([{ type: 'error', name: 'DUPLICATE' ,content: `檔案名稱已存在，請變更名稱或覆寫` }])
          failFiles.push(Object.assign(file, {
            preview: URL.createObjectURL(file),
            uploadName: file.uploadName
          }))
        hasDupli = true
      }
    }))
    setHasDuplicate(hasDupli)

    addLog(`[${folderId}] 目錄照片上傳完成`)
    setSubmitting(false)
    if(failMessages.length!==0) {
      setMessages(failMessages)
      setFiles(failFiles)
    } else {
      // updateLists()
      // setShowSuccess(true)
      setShow(false)
      reset()
    }
  }

  const handleRewriteSubmit = async () => {
    setRewriteSubmitting(true)
    addLog(`[${folderId}] 目錄照片上傳(覆寫)中...`)

    let failFiles = []
    let failMessages = []
    await Promise.all(files.map(async (file, index) => {
      const response = await addPhotoApi(folderId, file, [], true, photosProgressRef.current[file.uploadName])
      if(response.status==='success') {
        addLog(`[${file.uploadName}] 上傳(覆寫)完成`)
      } 
      if(response.status==='fail') {
        addLog(`[${file.uploadName}] 上傳(覆寫)失敗！`)
        failMessages.push(response.messages)
        failFiles.push(Object.assign(file, {
          preview: URL.createObjectURL(file),
          uploadName: file.uploadName
        }))
      }
    }))

    addLog(`[${folderId}] 目錄照片上傳(覆寫)完成`)
    setRewriteSubmitting(false)
    if(failMessages.length!==0) {
      setMessages(failMessages)
      setFiles(failFiles)
    } else {
      // updateLists()
      // setShowSuccess(true)
      setShow(false)
      reset()
    }
  }

  const handleOnChange = e => {
    const value = e.target.value
    if(value === 'new_folder') {
      props.modalsRef.current.newFolderModal.setShow(true)
    } else {
      setFolderId(value)
    }    
  }

  return (
    <Modal 
      show={show} 
      onHide={()=>setShow(false)}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      size="lg"
      backdrop={!submitting ? true : 'static'}>
      <Modal.Header closeButton={!submitting}>
        <Modal.Title as="h5">上傳照片</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={e => { e.preventDefault() }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>選擇目錄</Form.Label>
            <Form.Select size="sm" value={folderId} onChange={handleOnChange} disabled={submitting} style={{ boxSizing: 'border-box' }}>
              <option value=''>未選取</option>
              {folderOptions.map((f, i) => <option key={i} value={f.id}>{f.id}</option>)}
              <option value="new_folder">＋ 新增目錄</option>
            </Form.Select>
          </Form.Group>
          
          <ImagesUploader 
            files={files} setFiles={setFiles} 
            messages={messages} setMessages={setMessages} 
            disabled={submitting}
            photosProgressRef={photosProgressRef} />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {hasDuplicate && (messages.length && messages.map(ms => ms ? ms.filter(m => m.type==='error' && m.name==='DUPLICATE').length : 0).reduce((a, b) => a+b)!==0) ?
          <Button 
            disabled={
              folderId==='' || rewriteSubmitting ||
              (messages.length && messages.map(ms => ms ? ms.filter(m => m.type==='error' && m.name!=='DUPLICATE').length : 0).reduce((a, b) => a+b)>0)} 
            variant="secondary" size="sm" onClick={handleRewriteSubmit}>
            上傳(覆寫){rewriteSubmitting ? '中...' : ''}
          </Button> :
          <Button 
            disabled={
              folderId==='' || submitting ||
              (messages.length && messages.map(ms => ms ? ms.filter(m => m.type==='error').length : 0).reduce((a, b) => a+b)>0)} 
            variant="secondary" size="sm" onClick={handleSubmit}>
            上傳{submitting ? '中...' : ''}
          </Button>}
      </Modal.Footer>
    </Modal> 
  )
})

const ImagesUploader = props => {
  const { files, setFiles, messages, setMessages, disabled } = props
  // const photosRef = useRef([])

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': [] },
    // getFilesFromEvent: event => myCustomFileGetter(event),
    onDrop: acceptedFiles => {
      const newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
        uploadName: file.name,
        // dom: photosRef[file]
      }))
      setFiles(prevState => ([ ...prevState, ...newFiles ]));
    }
  })

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files])

  const handleRemoveFile = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    let currentFiles = [...files]
    currentFiles.splice(index, 1)
    let currentMessages = [...messages]
    currentMessages.splice(index, 1)
    setFiles(currentFiles)
    setMessages(currentMessages)
  }

  const handleOnChange = (e, index) => {
    const value = e.target.value.trim()
    let currentFiles = files
    currentFiles[index].uploadName = value
    setFiles([...currentFiles])

    let currentMessages = [...messages]
    let newMessages = []
    const rules = ['required', 'fileNameFormat']
    rules.map(r => {
      const response = validators[r](value)
      if(!response.passed) newMessages.push(...response.messages)
    })
    currentMessages[index] = newMessages
    setMessages([...currentMessages])
  }

  const renderFiles = files.map((f, i) => (
    <Col xs={6} key={`upload-photo-${i}-${f.name}`}>
      <Card key={f.name} onClick={e=>e.stopPropagation()} style={{ marginBottom: '.5rem' }}>
        <CloseButton 
          style={{ position: 'absolute', right: '4px', top: '4px', backgroundColor: 'white' }}
          onClick={e=>handleRemoveFile(e, i)}
        />
        <Card.Img 
          // key={`upload-photo-preview-url-${i}-${f.name}`}
          variant="top" src={f.preview} style={{ width: '100%', height: '180px', objectFit: 'contain', backgroundColor: 'rgb(0, 0, 0)' }} />
        <div 
          ref={ref => props.photosProgressRef.current[f.uploadName] = ref} 
          style={{ 
            width: '0%', height: '4px', backgroundColor: '#6bc7f1', whiteSpace: 'nowrap',
            fontSize: '12px', lineHeight: '14px', }} />
        <Card.Body>
          <Form.Control 
            style={{ boxSizing: 'border-box' }}
            type="text" 
            size="sm"
            placeholder="檔案名稱" 
            value={f.uploadName}
            onChange={e=>handleOnChange(e, i)} 
            aria-label="Small" />
            
          {messages[i] && messages[i].map((m, j) => 
              <Form.Text key={`m-${i}-${j}`} className="text-danger">{m.content}</Form.Text>
            )}
          {/* <Card.Text>檔案尺寸：{f.size}<br/>檔案類型：{f.type}</Card.Text> */}
            {/* {f.name} has <strong>myProps</strong>: {f.myProp === true ? 'YES' : ''} */}
        </Card.Body>
      </Card>
    </Col>
  ))

  return (
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>選擇照片</Form.Label>
      <Card 
        {...getRootProps({className: 'dropzone'})} 
        style={{ 
          padding: '8px', display: 'block', height: '380px', overflowY: 'auto', cursor: 'pointer',
          backgroundColor: !disabled ? '#f8f9fa' : '#e9ecef' }}>
        <input {...getInputProps()} />
        
        <Row style={{ pointerEvents: !disabled ? 'all' : 'none' }}>
          {renderFiles}
          <Col xs={6}>
            <Card border="dark" style={{     
              marginBottom: '.5rem',
              height: 'calc(100% - .5rem)',
              minHeight: '240px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderStyle: 'dashed',
              cursor: 'pointer' }}>
                <Button variant="outline-secondary">＋ 新增照片</Button>
            </Card>
          </Col>
        </Row>
      </Card>
    </Form.Group>
  )
}
/*
async function myCustomFileGetter(event) {
  const files = [];
  const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;

  for (var i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);
    
    Object.defineProperty(file, 'myProp', {
      value: true
    });

    files.push(file);
  }

  return files;
}
*/