import { roundToNDecimalPlaces } from '@utils/index.js'

// const DarkYellow = '#FAAC08'

export const drawRegion = (
  ctx, scale, region, color = 'white', labelPos = 'top', active = null
) => {
  
  let labelText = '', labelPosY
  if (region.label) labelText += `${region.label}`
  if (region.score !== undefined) labelText += ` (${roundToNDecimalPlaces(region.score, 2)})`

  const box = {
    left: Math.round(region.box.left * scale),
    top: Math.round(region.box.top * scale), 
    width: Math.round(region.box.width * scale),
    height: Math.round(region.box.height * scale)
  }

  if (labelText) {
    if (labelPos === 'top') {
      ctx.textBaseline = 'top'
      labelPosY = box.top - 16
    } else {
      ctx.textBaseline = 'top'
      labelPosY = box.top + box.height + 4
    }

    ctx.font = `14px Noto Sans Mono`
    // ctx.strokeStyle = 'black'
    // ctx.lineWidth = 1.4 * scale
    // ctx.strokeText(labelText, box.left, labelPosY)

    ctx.fillStyle = 'rgba(15, 37, 64, 0.85)'
    ctx.fillRect(
      box.left - 2, labelPosY - 2,
      Math.round(ctx.measureText(labelText).width) + 4, 16
    )

    ctx.fillStyle = color
    ctx.fillText(labelText, box.left, labelPosY)
  }

  drawSquare(ctx, box, color)
}

const drawSquare = (ctx, box, color) => {
  ctx.strokeStyle = 'black'
  ctx.lineWidth = 4
  ctx.strokeRect(box.left, box.top, box.width, box.height)

  ctx.strokeStyle = color
  ctx.lineWidth = 2
  ctx.strokeRect(box.left, box.top, box.width, box.height)
}

export const drawText = (ctx, text, posX, posY) => {
  ctx.textBaseline = 'bottom'
  ctx.font = `14px Noto Sans Mono`
  ctx.strokeStyle = 'black'
  ctx.lineWidth = 2
  ctx.strokeText(text, posX, posY)
  ctx.fillStyle = 'white'
  ctx.fillText(text, posX, posY)
}

/*
export const drawSquareCorners = (ctx, scale, box, color, activeCorner) => {
  const cornerWidth = 7 * scale

  ctx.strokeStyle = 'black'
  ctx.lineWidth = 5 * scale
  ctx.strokeRect(box.left, box.top, cornerWidth, cornerWidth)
  ctx.strokeRect((box.left + box.width - cornerWidth), box.top, cornerWidth, cornerWidth)
  ctx.strokeRect(box.left, (box.top + box.height - cornerWidth), cornerWidth, cornerWidth)
  ctx.strokeRect((box.left + box.width - cornerWidth), (box.top + box.height - cornerWidth), cornerWidth, cornerWidth)

  const hoverColor = DarkYellow
  ctx.lineWidth = 2.4 * scale
  ctx.strokeStyle = (activeCorner === 'leftTop') ? hoverColor : color
  ctx.strokeRect(box.left, box.top, cornerWidth, cornerWidth)
  ctx.strokeStyle = (activeCorner === 'rightTop') ? hoverColor : color
  ctx.strokeRect((box.left + box.width - cornerWidth), box.top, cornerWidth, cornerWidth)
  ctx.strokeStyle = (activeCorner === 'leftBottom') ? hoverColor : color
  ctx.strokeRect(box.left, (box.top + box.height - cornerWidth), cornerWidth, cornerWidth)
  ctx.strokeStyle = (activeCorner === 'rightBottom') ? hoverColor : color
  ctx.strokeRect((box.left + box.width - cornerWidth), (box.top + box.height - cornerWidth), cornerWidth, cornerWidth)
}
*/