import React, { useContext, useEffect } from 'react'
import firebaseApp from '../../app/firebaseApp'
import firebase from 'firebase'
import useStore from '@stores'
import { getUserApi } from '@scripts/main.js'

const FirebaseAuth = props => {
  const auth = firebase.auth(firebaseApp)
  const setUser = useStore(state => state.setUser)
  const setAuthChecked = useStore(state => state.setAuthChecked)
  // const setPreferences = useStore(state => state.setPreferences)
  
  useEffect(() => {
    auth.onAuthStateChanged(async (currentUser) => {
      if(currentUser) {
        const response = await getUserApi(currentUser)
        if(response.status==='success') {
          setUser({
            uid: currentUser.uid,
            email: currentUser.email,
            name: response.data.name,
            role: response.data.role,
            preferences: response.data.preferences ? response.data.preferences : null
          })
          // if (response.data.preferences?.predictConfig !== undefined)
            // setPreferences('predictConfig', response.data.preferences.predictConfig)
        }
      }
      setAuthChecked(true)
    })
  }, [])

  return <>{props.children}</>
}

export default FirebaseAuth