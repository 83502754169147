import React, { useState, createContext } from 'react'

export const AuthContext = createContext({
  user: null,
  setUser: ()=>{},
  authChecked: false,
  setAuthChecked: ()=>{},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authChecked, setAuthChecked] = useState(false)

  return (
    <AuthContext.Provider value={{ user, setUser, authChecked, setAuthChecked }}>
      {children}
    </AuthContext.Provider>
  )
}

export const AuthConsumer = AuthContext.Consumer;