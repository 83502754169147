import React, { forwardRef } from 'react'
import Top from './Top'
import Bottom from './Bottom'

import styles from './main.module.scss'

export default forwardRef((props, ref) => 
  <div ref={ref} className={styles.rightWrapper}>
    <Top />
    <Bottom />
  </div>
)