import React, { useCallback, useMemo, useRef } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import ListGroup from 'react-bootstrap/ListGroup'
import ExpandedText from './ExpandedText.jsx'
import IconFolder from '@assets/icons/folder'
import IconFolderOpen from '@assets/icons/folder-open'
import FileTypeDolphin from '@assets/icons/file-type-dolphin.js'

import useStore from '@stores'

const FileList = props => {
  const { scrollRef, active } = props
  const ref = useRef(null)

  return (
    <div ref={ref} style={{ height: '100%' }}>
      <ExpandedText wrapperRef={ref} scrollRef={scrollRef} active={active} />
      <FolderLists />
    </div>
  )
}

const FolderLists = () => {
  const photosByFolder = useStore(state => state.photosByFolder)

  return (
    photosByFolder.map((folder, i) => 
      <FolderList
        key={`folder-list-${i}-${folder.id}`} 
        folder={folder}
      />
    )
  )
}

const FolderList = props => {
  // const updateActiveFolderId = useStore(state => state.updateActiveFolderId)
  const { folder } = props
  
  // const handleFolderOnClick = useCallback(e => {
  //   e.preventDefault()
    
  //   if(e.target.classList.contains('collapsed') || e.target.parentElement.classList.contains('collapsed')) {
  //     updateActiveFolderId(folder.id)
  //   }
  // }, [folder.id])

  const renderFolderItem = () =>
    <Accordion.Header
      // onClick={handleFolderOnClick}
    >
      <IconFolder className="svg-inline--fa fa-folder" />
      <IconFolderOpen className="svg-inline--fa fa-folder-open" />
      <span className="text expandable-text">{folder.id}</span>
    </Accordion.Header>

  return (
    <Accordion id={`folder-${folder.id}`} flush>
      <Accordion.Item eventKey={`folder-${folder.id}`}>
        {renderFolderItem()}
        <Accordion.Collapse eventKey={`folder-${folder.id}`}>
          <FileItems 
            // cachedData={cachedData} 
            folder={folder} />
        </Accordion.Collapse>
      </Accordion.Item>
    </Accordion>
  )
}

const FileItems = props => {
  const { folder, 
    // cachedData 
  } = props
  const folderId = folder.id

  return (
    <ListGroup variant="flush">
      {folder.photos.map((photoId, j) => {
        // const idPath = `${folderId}/${photoId}`
        // const cached = Object.keys(cachedData).indexOf(idPath)!==-1
        // const showed = cachedData[idPath] || false

        return (
          <FileItem 
            folderId={folderId}
            photoId={photoId}
            // cached={cached}
            // showed={showed}
            key={`file-list-${folderId}-${photoId}`}
          />
        )
      })}
    </ListGroup>
  )
}

const FileItem = props => {
  const selectPhoto = useStore(state => state.selectPhoto)
  const activeIds = useStore(state => state.activeIds)
  const { folderId, photoId } = props;
  const active = activeIds.folder===folderId && activeIds.photo===photoId

  const handleSelect = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
    selectPhoto(folderId, photoId)
  }, [])

  return useMemo(() => (
    <ListGroup.Item
      key={`file-item-${folderId}-${photoId}`}
      onClick={handleSelect} 
      // className={`${active ? 'active' : ''} ${cached ? 'cached' : ''} ${showed ? 'showed' : ''}`}
      className={`intend-1 ${active ? 'active' : ''}`}
    >
      <FileTypeDolphin className="svg-inline--fa" />
      <span className="text expandable-text">{photoId}</span>
    </ListGroup.Item>
  ), [active])
}

export default FileList