import React, { useEffect, useRef } from 'react'

import useStore from '@stores'

import cx from 'classnames'
import styles from './main.module.scss'

import { smoothlyScrollTo } from '@utils'

import IconClose from '@assets/icons/close'

const Tabs = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const cachedPhotoData = useStore(state => state.cachedPhotoData)
  const clearCachedPhoto = useStore(state => state.clearCachedPhoto)
  const selectPhoto = useStore(state => state.selectPhoto)
  
  const ref = useRef(null)

  useEffect(() => {
    ref.current && ref.current.addEventListener('wheel', handleWheel)
    return () => ref.current && ref.current.removeEventListener('wheel', handleWheel)
  }, [])

  const handleWheel = e => {
    const delta = Math.abs(e.deltaY) >= Math.abs(e.deltaX) ? e.deltaY : e.deltaX * 0.75
    ref.current.scrollLeft += (delta * 3)
    e.preventDefault()
  }

  const handeClickClose = (e, idPath) => {
    e.stopPropagation()
    clearCachedPhoto(cachedPhotoData[idPath].folderId, cachedPhotoData[idPath].photoId)
  }

  useEffect(() => {
    if (hasActiveIdPath()) {
      const activeTab = document.querySelector('[data-tab-status="true"]')
      smoothlyScrollTo(ref.current, activeTab.offsetLeft - ref.current.clientWidth + activeTab.offsetWidth + 100, 300)
    }
  }, [activeIds])

  return (
    <div ref={ref} className={cx(styles.tabs, 'hide-scrollbar')}>
      {Object.keys(cachedPhotoData)
        .filter(idPath => cachedPhotoData[idPath].show)
        .map((idPath) => {
          const active = activeIds.folder===cachedPhotoData[idPath].folderId && activeIds.photo===cachedPhotoData[idPath].photoId

          return (
            <div 
              key={`center-tab-${idPath}`}
              data-tab-status={active} 
              className={cx(styles.tab, { [styles.activeTab]: active })}
              onClick={()=>selectPhoto(cachedPhotoData[idPath].folderId, cachedPhotoData[idPath].photoId)}
            >
              {cachedPhotoData[idPath].photoId}
              <div
                data-photo-status={active}
                className={styles.closeBtn} 
                onClick={e=>handeClickClose(e, idPath)}
              >
                <IconClose />
              </div>
            </div>
          )
        })}
    </div>
  )
}
export default Tabs