import { create } from 'zustand'
import dayjs from 'dayjs'
import { monitorFoldersApi, monitorPhotosApi, getPhotoSizeApi, identifyPhotoApi, updatePhotoDataApi, updatePhotoRegionsApi, updatePhotoUrlApi } from '@scripts/main.js'
import { roundToNDecimalPlaces, clampUnit } from '@utils/index'
import { v4 as uuidv4 } from 'uuid'
import CONS from './constant'

const useStore = create((set, get) => ({
  user: null,
  setUser: data => set(() => ({ user: data })),

  authChecked: false,
  setAuthChecked: bol => set(() => ({ authChecked: bol })),

  userBehaviorMode: CONS.BEHAVIOR_MODE_VIEW,
  setUserBehaviorMode: mode => set(() => ({ userBehaviorMode: mode })),

  predictionAutomaticImport: false,
  setPredictionAutomaticImport: bol => set(() => ({ predictionAutomaticImport: bol })),

  showPredictResults: true,
  setShowPredictResults: bol => set(() => ({ showPredictResults: bol })),

  logs: [],
  addLog: newLog => set(prev => ({ logs: [...prev.logs, `${dayjs(new Date()).format('YYYY/MM/DD HH:mm:ss')}> ${newLog}`] })),

  activeIds: { folder: null, photo: null },
  hasActiveIdPath: () => get().activeIds && get().activeIds.folder && get().activeIds.photo,
  getActiveIdPath: () => `${get().activeIds.folder}/${get().activeIds.photo}`,
  updateActiveIds: (folderId, photoId) => set(() => ({ activeIds: { folder: folderId, photo: photoId } })),
  updateActiveFolderId: folderId => set(() => ({ activeIds: { folder: folderId, photo: null } })),

  photosByFolder: [],
  initPhotosByFolder: data => set(() => ({ photosByFolder: data })),
  labelList: [],
  initLabelList: data => set(() => ({ labelList: data })),
  
  labelThreshold: 10,
  setLabelThreshold: value => set(() => ({ labelThreshold: value})),
  trainedLabels: [],
  setTrainedLabels: data => set(() => ({ trainedLabels: data})),
  preferences: { predictConfig: { score: 0.1, iou: 0.1, topk: 20, model: 'model1' }}, // 若讀不到資料庫的偏好設定，才會使用這預設值
  setPreferences: (key, data) => {
    set(prev => ({ preferences: { ...prev.preferences, [key]: data }}))
  },

  collData: {},
  updateCollData: data => set(() => ({ collData: data })),

  monitorCollData: (callback) => {
    let state = 'init' // counts = 0

    monitorFoldersApi((changes) => {
      let collData = get().collData
      const user = get().user
      changes.map((doc) => {
        // 屬於使用者權限的目錄才變更
        if ((user.role==='admin') || (user.role==='maintainer') || (user.role==='editor' && user.uid===doc.uid)) {
          // console.log(`${doc.type} ${folderId}`)
          if (doc.type === 'added' && !collData[doc.folderId]) {
            collData[doc.folderId] = { photos: {} }
          }
          if (doc.type === 'removed' && collData[doc.folderId]) {
            delete collData[doc.folderId]
          }
        }
        // counts++
      })
      // console.log(`Changed: ${counts}`)
      get().updateLists(collData, false)
    })

    monitorPhotosApi((changes) => {
      let collData = get().collData
      changes.map((doc) => {
        // 已存在的目錄(屬於使用者權限)才變更
        if (collData[doc.folderId]) {
          // console.log(`${doc.type} ${folderId}/${photoId}, ${JSON.stringify(regions)}`)
          if (doc.type === 'added') {
            if (!collData[doc.folderId].photos[doc.photoId])
              collData[doc.folderId].photos[doc.photoId] = {}
            collData[doc.folderId].photos[doc.photoId] = {
              photoUrl: doc.photoUrl, size: doc.size, regions: doc.regions
            }
          }
          if (doc.type === 'modified') {
            collData[doc.folderId].photos[doc.photoId] = {
              photoUrl: doc.photoUrl, size: doc.size, regions: doc.regions
            }
          }
          if (doc.type === 'removed') {
            delete collData[doc.folderId].photos[doc.photoId]
          }
          get().updateCachedPhotos(doc)
        }
        // counts++
      })
      // console.log(`Changed: ${counts}`)
      get().updateLists(collData, true)

      callback(state)
      if (state==='init') state = 'monitor'
    })
  },

  updateLists: (collData, photoChanged) => {
    get().updateCollData(collData)
    const folderList = Object.keys(collData).sort().map(fi => ({
      id: fi,
      photos: Object.keys(collData[fi].photos).sort()
    }))
    get().initPhotosByFolder(folderList)

    if (photoChanged) {
      let byLabels = {}
      Object.keys(collData).map(fi => {
        Object.keys(collData[fi].photos).map(pi => {
          collData[fi].photos[pi].regions.map(ri => {
            if (!byLabels[ri.label]) byLabels[ri.label] = {}
            if (!byLabels[ri.label][fi]) byLabels[ri.label][fi] = {}
            if (!byLabels[ri.label][fi][pi]) byLabels[ri.label][fi][pi] = []
            byLabels[ri.label][fi][pi].push(ri.box)
          })
        })
      })
      const labelList = Object.keys(byLabels).sort((a, b) => a - b).map(ln => ({
        name: ln,
        folders: Object.keys(byLabels[ln]).sort().map(fi => ({
          id: fi,
          photos: Object.keys(byLabels[ln][fi]).sort().map(pi => ({
            id: pi,
            boxs: byLabels[ln][fi][pi]
          }))
        }))
      }))
      get().initLabelList(labelList)
    }
  },

  // 即時更新其他使用者的暫存資料
  // 當照片被多位使用者開啟，且被某位使用者修改時，須即時更新其他使用者的暫存資料。
  updateCachedPhotos: (doc) => {
    const idPath = `${doc.folderId}/${doc.photoId}`
    if (get().cachedPhotoData[idPath]) {
      // added不須更新
      if (doc.type === 'modified') {
        get().updateCachedPhotoData(idPath, { photoUrl: doc.photoUrl, size: doc.size })
        // 標記框只更新targetStorage
        get().updateCachedPhotoRegionsTargetStorage(idPath, doc.regions)
      }
      if (doc.type === 'removed') {
        get().clearCachedPhoto(doc.folderId, doc.photoId)
      }
    }
  },

  clearCachedPhoto: (folderId, photoId) => {
    const idPath = `${folderId}/${photoId}`
    if (folderId === get().activeIds.folder && photoId === get().activeIds.photo) {
      // get().switchToFirstShowingCachedData(idPath)
      get().switchToPreviousShowingCachedData(idPath)
    }
    set(prev => {
      // prev.photosByFolder[folderIndex].photos = prev.photosByFolder[folderIndex].photos.filter(d => d !== photoId)
      delete prev.cachedPhotoData[idPath]
      delete prev.cachedPhotoRegions[idPath]
      return ({
        // photosByFolder: [...prev.photosByFolder],
        cachedPhotoData: { ...prev.cachedPhotoData },
        cachedPhotoRegions: { ...prev.cachedPhotoRegions }
      })
    })
  },

  renameCachedPhoto: (folderId, photoId, newPhotoId, newPhotoUrls) => {
    set(prev => {
      const preIdPath = `${folderId}/${photoId}`
      const newIdPath = `${folderId}/${newPhotoId}`

      if (prev.cachedPhotoData[preIdPath]) {
        prev.cachedPhotoData[newIdPath] = { ...prev.cachedPhotoData[preIdPath], photoId: newPhotoId, photoUrl: newPhotoUrls[newIdPath] }
        prev.cachedPhotoRegions[newIdPath] = { ...prev.cachedPhotoRegions[preIdPath] }
        delete prev.cachedPhotoData[preIdPath]
        delete prev.cachedPhotoRegions[preIdPath]
      }
      return ({
        cachedPhotoData: { ...prev.cachedPhotoData },
        cachedPhotoRegions: { ...prev.cachedPhotoRegions }
      })
    })

    if (get().activeIds.photo === photoId) {
      get().updateActiveIds(folderId, newPhotoId)
    }

    // get().updateLists()
  },

  renameCachedFolder: (folderId, newFolderId, newPhotoUrls)  => {
    set(prev => {
      Object.keys(prev.cachedPhotoData).map(idPath => {
        const ppFolderId = idPath.split('/')[0]
        const ppPhotoId = idPath.split('/')[1]
        if(ppFolderId === folderId) {
          const preIdPath = `${folderId}/${ppPhotoId}`
          const newIdPath = `${newFolderId}/${ppPhotoId}`
          prev.cachedPhotoData[newIdPath] = { ...prev.cachedPhotoData[preIdPath], folderId: newFolderId, photoUrl: newPhotoUrls[newIdPath] }
          prev.cachedPhotoRegions[newIdPath] = { ...prev.cachedPhotoRegions[preIdPath] }
          delete prev.cachedPhotoData[preIdPath]
          delete prev.cachedPhotoRegions[preIdPath]
        }
      })
      return ({
        cachedPhotoData: { ...prev.cachedPhotoData },
        cachedPhotoRegions: { ...prev.cachedPhotoRegions }
      })
    })

    if (get().activeIds.folder === folderId) {
      //get().updateActiveIds(null, null)
      get().updateActiveIds(newFolderId, get().activeIds.photo)
    }

    // get().updateLists()
  },

  cachedPhotoData: {}, // [idPath]: { folderId, photoId, show, photoUrl, zoom, predictPhotoUrl }
  updateCachedPhotoData: (idPath, data) => {
    set(prev => ({
      cachedPhotoData: {
        ...prev.cachedPhotoData,
        [idPath]: { ...prev.cachedPhotoData[idPath], ...data }
      }
    }))
  },

  cachedPhotoRegions: {}, // [idPath]: { targetStorage, targetManual, targetPredict, predict, originTargetRegionsString, activeIds }
  updateCachedPhotoRegions: (idPath, data) => {
    set(prev => ({
      cachedPhotoRegions: {
        ...prev.cachedPhotoRegions,
        [idPath]: { ...prev.cachedPhotoRegions[idPath], ...data }
      }
    }))
  },

  /*
  getCachedPhotoShowingByFolder: (folderId) => {
    let result = {}
    Object.keys(get().cachedPhotoData).map(key => { if (key.split('/')[0] === folderId) result[key] = get().cachedPhotoData[key].show })
    return result
  },
  */

  initCachedPhoto: (idPath, rawData) => {
    const basicData = {
      photoUrl: rawData.photoUrl,
      size: rawData.size ? rawData.size : null,
      resetZoom: false,
      predictPhotoUrl: null
    }
    let regions = []
    if (Array.isArray(rawData.regions)) {
      rawData.regions.map(r => {
        regions.push({
          box: {
            height: r.box.height,
            left: r.box.left,
            top: r.box.top,
            width: r.box.width,
            xmin: r.box.xmin ? r.box.xmin : null,
		        ymin: r.box.ymin ? r.box.ymin : null,
		        xmax: r.box.xmax ? r.box.xmax : null,
		        ymax: r.box.ymax ? r.box.ymax : null,
          },
          label: r.label || '',
        })
      })
    }
    get().updateCachedPhotoData(idPath, basicData)
    const targetStorage = regions.map(r => ({ id: uuidv4(), ...r }))
    get().updateCachedPhotoRegions(idPath, {
      targetStorage,
      targetManual: [],
      targetPredict: [],
      predict: null,
      originTargetRegionsString: JSON.stringify(targetStorage),
      activeIds: [],
      hovering: null,
    })
  },
  
  updateCachedPhotoRegionsTargetStorage: (idPath, regions) => {
    const targetStorage = regions.map(r => ({ id: uuidv4(), ...r }))
    get().updateCachedPhotoRegions(idPath, {
      targetStorage,
      originTargetRegionsString: JSON.stringify(targetStorage),
    })
  },

  updateCachedPhotoPredictPhotoUrl: (idPath, url) => {
    get().updateCachedPhotoData(idPath, { predictPhotoUrl: get().cachedPhotoData[idPath].predictPhotoUrl===url ? null : url })
  },

  updateCachedPhotoActiveRegionIds: (idPath, newId) => {
    const activeIds = get().cachedPhotoRegions[idPath].activeIds
    get().updateCachedPhotoRegions(idPath, { activeIds: activeIds.indexOf(newId) === -1 ? [newId] : [] })
  },

  selectAllCachedPhotoActiveRegionIds: (idPath, ids) => {
    get().updateCachedPhotoRegions(idPath, { activeIds: ids })
  },

  updateCachedPhotoHovering: (idPath, newHovering) => {
    if (JSON.stringify(get().cachedPhotoRegions[idPath].hovering) !== JSON.stringify(newHovering)) {
      get().updateCachedPhotoRegions(idPath, { hovering: newHovering})
    }
  },

  /*
  zoomIn: idPath => {
    const translate = get().cachedPhotoData[idPath].translate
    const currentSize = get().cachedPhotoData[idPath].currentSize
    const ratio = 1.2
    if (currentSize.width * ratio < 10000) {
      get().updateCachedPhotoData(idPath, { translate: {
        x: Math.round(translate.x + currentSize.width/2  * (1-ratio)),
        y: Math.round(translate.y + currentSize.height/2 * (1-ratio)),
        scale: translate.scale * ratio,
      }})
    }
  },
  zoomOut: idPath => {
    const translate = get().cachedPhotoData[idPath].translate
    const currentSize = get().cachedPhotoData[idPath].currentSize
    const ratio = 1.2
    if (currentSize.width / ratio > 300) {
      get().updateCachedPhotoData(idPath, { translate: {
        x: Math.round(translate.x + currentSize.width/2  * (1-1/ratio)),
        y: Math.round(translate.y + currentSize.height/2 * (1-1/ratio)),
        scale: translate.scale / ratio,
      }})
    }
  },
  */
  zoomOrigin: idPath => {
    get().updateCachedPhotoData(idPath, { resetZoom: true })
    //get().updateCachedPhotoData(idPath, { translate: { x: 0, y: 0, scale: 1.0 }})
  },

  loadingPhoto: {},
  setLoadingPhoto: (idPath, bol) => set(prev => ({ loadingPhoto: { ...prev.loadingPhoto, [idPath]: bol } })),

  selectPhoto: (folderId, photoId) => {
    if (get().activeIds.folder !== folderId || get().activeIds.photo !== photoId) {
      const idPath = `${folderId}/${photoId}`
      get().updateActiveIds(folderId, photoId)
      get().updateCachedPhotoData(idPath, { folderId, photoId, show: true })

      if (!get().cachedPhotoData[idPath].photoUrl && !get().loadingPhoto[idPath]) {
        get().setLoadingPhoto(idPath, true);
      }
    }
  },

  /*
  hidePhoto: (folderId, photoId) => {
    const idPath = `${folderId}/${photoId}`
    if (folderId === get().activeIds.folder && photoId === get().activeIds.photo) {
      // get().switchToFirstShowingCachedData(idPath)
      get().switchToPreviousShowingCachedData(idPath)
    }
    set(prev => {
      delete prev.cachedPhotoData[idPath]
      delete prev.cachedPhotoRegions[idPath]
      return ({
        cachedPhotoData: { ...prev.cachedPhotoData },
        cachedPhotoRegions: { ...prev.cachedPhotoRegions }
      })
    })
  },
  */

  predictingPhoto: {},
  setPredictingPhoto: (idPath, bol) => set(prev => ({ predictingPhoto: { ...prev.predictingPhoto, [idPath]: bol } })),

  predictPhoto: async (idPath) => {
    const photoId = idPath.split('/')[1]
    const params = {
      photoUrl: get().cachedPhotoData[idPath].photoUrl,
      width: roundToNDecimalPlaces(get().cachedPhotoData[idPath].currentSize.width, 0),
      height: roundToNDecimalPlaces(get().cachedPhotoData[idPath].currentSize.height, 0),
      model: get().preferences.predictConfig.model,
      score: get().preferences.predictConfig.score,
      iou: get().preferences.predictConfig.iou,
      topk: get().preferences.predictConfig.topk,
    }
    get().setPredictingPhoto(idPath, true)
    get().addLog(`[${photoId}] 海豚背鰭辨識中...`)
    console.log('Predict Parameters', params)
    const response = await identifyPhotoApi(params) || []
    get().setPredictingPhoto(idPath, false)
    if (response.status === 'success') {
      get().addLog(`[${photoId}] 海豚背鰭辨識完成`)
      console.log('Predict Result', response.data)
      const regions = response.data.predictions.map(r => ({
        id: uuidv4(),
        box: { ...r.box },
        label: (r.label==='None_of_the_above') ? '999' : r.label,
        score: r.score
      }))
      get().updateCachedPhotoRegions(idPath, {
        predict: regions,
        targetPredict: get().predictionAutomaticImport ? regions.map(r => ({ 
          id: r.id, box: { ...r.box },
          label: (r.label==='None_of_the_above') ? '999' : r.label
        })) : []
      })
    }
    if (response.status === 'fail') {
      get().addLog(`[${photoId}] 辨識發生不可預期的錯誤！`)
    }
  },

  checkPhotoSize: async (idPath, photoSize) => {
    const size = get().cachedPhotoData[idPath].size
    if (!size || Object.keys(size).length===0) {
      const folderId = idPath.split('/')[0]
      const photoId = idPath.split('/')[1]
      const data = { size: photoSize }
      const response = await updatePhotoDataApi(folderId, photoId, data)
      if (response.status === 'success') {
        get().addLog(`[${photoId}] 照片尺寸更新完成`)
        get().updateCachedPhotoData(idPath, data)
      }
      if (response.status === 'fail') {
        get().addLog(`[${photoId}] 照片尺寸更新失敗`)
      }
    }
  },

  saveRegions: async (idPath) => {
    const folderId = idPath.split('/')[0]
    const photoId = idPath.split('/')[1]
    get().addLog(`[${photoId}] 標記內容上傳中...`)

    const size = get().cachedPhotoData[idPath].size
    const hasSize = (size || Object.keys(size).length!==0)
    const currentRegions = get().cachedPhotoRegions[idPath]
    const newRegions = 
      Object.keys(currentRegions)
        .filter(t => t === 'targetStorage' || t === 'targetManual' || t === 'targetPredict').map(type => currentRegions[type]).flat()
        .map(r => ({ ...r, box: { 
          left: Math.round(r.box.left), 
          top: Math.round(r.box.top), 
          width: Math.round(r.box.width), 
          height: Math.round(r.box.height),
          xmin: hasSize ? clampUnit(r.box.left / size.width) : null,
          ymin: hasSize ? clampUnit(r.box.top / size.height) : null,
          xmax: hasSize ? clampUnit((r.box.left + r.box.width) / size.width) : null,
          ymax: hasSize ? clampUnit((r.box.top + r.box.height) / size.height) : null,
        } }))
    const response = await updatePhotoRegionsApi(folderId, photoId, newRegions)
    if (response.status === 'success') {
      get().addLog(`[${photoId}] 標記內容上傳完成`)
      get().updateCachedPhotoRegions(idPath, {
        targetStorage: newRegions,
        targetManual: [],
        targetPredict: [],
        // predict: null,
        originTargetRegionsString: JSON.stringify(newRegions),
      })
      // get().updateCachedPhotoData(idPath, { predictPhotoUrl: null })
      // get().updateLists()
    }
    if (response.status === 'fail') {
      get().addLog(`[${photoId}] 標記上傳發生不可預期的錯誤`)
    }
  },
  
  exportLabels: () => {
    if (get().labelList.length===0) {
      get().addLog(`標記統計匯出中止，沒有標記資料可匯出`)
      return
    }
    let csvContent = "data:text/csv;charset=utf-8,"
    + get().labelList.map(lo => 
      lo.folders.map(f => 
        f.photos.map(p => 
          p.boxs.map(b => 
            `${f.id}, ${p.id}, ${lo.name}, ${b.left}, ${b.top}, ${b.width}, ${b.height}\n`
          ).join('')
        ).join('')
      ).join('')
      + `統計數量, ${lo.name}, ${lo.folders.map(f => f.photos.map(p => p.boxs.length).reduce((a, b) => a+b)).reduce((a, b) => a+b)}\n`
    ).join('')

    let encodedUri = encodeURI(csvContent)
    let link = document.createElement("a");
    link.href = encodedUri
    link.download = `標記統計_${dayjs(new Date()).format('YYYYMMDD_HHmmss')}.csv`
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    get().addLog(`標記統計匯出完成`)
  },

  exportDataset: (threshold) => {
    const path = `gs://photo-id-app.appspot.com/photos`
    const assign = `UNASSIGNED`
    let count = {}, warning = false //failMessages = []
    get().addLog(`匯出標記資料集...`)

    if (get().labelList.length===0) {
      //return {
      //  status: 'fail',
      //  messages: [{ type: 'error', content: `沒有標記資料可匯出` }]
      //}
      console.log(`沒有標記資料可匯出`)
      get().addLog(`匯出標記資料集中止！請查看主控台訊息`)
    }

    get().labelList.map(lo => {
      // 統計標記數量，除了一般標記外，還包含999和empty
      count[lo.name] = lo.folders.map(f => f.photos.map(p => p.boxs.length).reduce((a, b) => a+b)).reduce((a, b) => a+b)
    })

    let dataset = get().labelList.map(lo => {
      //將empty、999、數量不足的標記，改為None_of_the_above標記
      const ln = (lo.name && (lo.name!=='999') && (count[lo.name]>=threshold)) ? lo.name : 'None_of_the_above'
      return lo.folders.map(f => 
        f.photos.map(p => 
          p.boxs.map(b => {
            // 檢查標記是否有相對座標，沒有就略過
            if (b.xmin!==null && b.ymin!==null && b.xmax && b.ymax) {
                const xmin = clampUnit(b.xmin)
                const ymin = clampUnit(b.ymin)
                const xmax = clampUnit(b.xmax)
                const ymax = clampUnit(b.ymax)
                return `${assign},${path}/${f.id}/${p.id},${ln},${xmin},${ymin},,,${xmax},${ymax},,\n`
            } else {
              //failMessages.push({
              //  type: 'error',
              //  message: `${f.id}/${p.id}，標記[${lo.name}]缺少相對座標`
              //})
              warning = true
              console.log(`${f.id}/${p.id}，標記[${lo.name}]缺少相對座標`)
              return ''
            }
          }).join('')
        ).join('')
      ).join('')
    }).join('')

    if (!dataset) {
      //return {
      //  status: 'fail',
      //  messages: [{ type: 'error', content: `沒有標記資料可匯出` }]
      //}
      console.log(`沒有標記資料可匯出`)
      get().addLog(`匯出標記資料集中止！請查看主控台訊息`)
    } else {
      const csvContent = "data:text/csv;charset=utf-8," + dataset
      const encodedUri = encodeURI(csvContent)
      let link = document.createElement("a");
      link.href = encodedUri
      link.download = `dataset_${dayjs(new Date()).format('YYYYMMDD_HHmmss')}.csv`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()

      //if (failMessages.length===0) {
      //  return { status: 'success' }
      //} else {
      //  return { status: 'warning', messages: failMessages }
      //}
      if (!warning) {
        get().addLog(`匯出標記資料集完成`)
      } else {
        get().addLog(`匯出標記資料集完成，請查看主控台訊息`)
      }
    }
  },

  importPredictToTarget: (idPath) => {
    get().updateCachedPhotoRegions(idPath, {
      targetPredict: get().cachedPhotoRegions[idPath].predict.map(r => ({
        id: r.id,
        box: { ...r.box },
        label: r.label
      }))
    })
  },

  deleteTargetRegion: (idPath, type, id) => {
    get().updateCachedPhotoRegions(idPath, {
      [type]: get().cachedPhotoRegions[idPath][type].filter(r => r.id !== id)
    })
    if (get().cachedPhotoRegions[idPath].activeIds.indexOf(id)!==-1) {
      get().updateCachedPhotoActiveRegionIds(idPath, id)
    }
  },

  updateTargetLabel: (idPath, type, id, value) => {
    get().updateCachedPhotoRegions(idPath, {
      [type]: get().cachedPhotoRegions[idPath][type].map(r => r.id === id ? ({ ...r, label: value }) : r)
    })
  },

  updateTargetBox: (idPath, id, value) => {
    const currentRegions = get().cachedPhotoRegions[idPath]
    const region =
      Object.keys(currentRegions)
        .filter(t => t === 'targetStorage' || t === 'targetManual' || t === 'targetPredict')
        .map(type => currentRegions[type].map(r => ({ ...r, type })))
        .flat()
        .filter(r => r.id === id)[0]
    get().updateCachedPhotoRegions(idPath, {
      [region.type]: get().cachedPhotoRegions[idPath][region.type].map(r => r.id === id ? ({ ...r, box: value }) : r)
    })
  },

  addManualRegion: (idPath, region) => {
    const id = uuidv4()
    get().updateCachedPhotoRegions(idPath, {
      targetManual: [
        ...get().cachedPhotoRegions[idPath].targetManual,
        { id, ...region, label: '' }
      ]
    })
    return id
  },

  switchToFirstShowingCachedData: (excludedIdPath) => {
    const newCachedArray = Object.keys(get().cachedPhotoData).filter(idPath => idPath !== excludedIdPath && get().cachedPhotoData[idPath].show)
    if (newCachedArray.length !== 0) {
      get().updateActiveIds(
        get().cachedPhotoData[newCachedArray[0]].folderId,
        get().cachedPhotoData[newCachedArray[0]].photoId
      )
    } else {
      get().updateActiveIds(null, null)
    }
  },

  getPreviousShowingCachedData: () => {
    const currentCachedArray = Object.keys(get().cachedPhotoData).filter(idPath => get().cachedPhotoData[idPath].show)
    if(get().hasActiveIdPath()) {
      const index = currentCachedArray.indexOf(get().getActiveIdPath())
      if(index === 0) return null
      else return currentCachedArray[index-1]
    } else {
      return null
    }
  },
  
  getNextShowingCachedData: () => {
    const currentCachedArray = Object.keys(get().cachedPhotoData).filter(idPath => get().cachedPhotoData[idPath].show)
    if(get().hasActiveIdPath()) {
      const index = currentCachedArray.indexOf(get().getActiveIdPath())
      if(index === currentCachedArray.length-1) return null
      else return currentCachedArray[index+1]
    } else {
      return null
    }
  },

  switchToPreviousShowingCachedData: (excludedIdPath) => {
    const excludedIndex = Object.keys(get().cachedPhotoData).findIndex(idPath => idPath === excludedIdPath)
    const prevIndex = (excludedIndex !== 0) ? excludedIndex - 1 : 0
    const newCachedArray = Object.keys(get().cachedPhotoData).filter(idPath => idPath !== excludedIdPath && get().cachedPhotoData[idPath].show)
    if (newCachedArray.length !== 0) {
      get().updateActiveIds(
        get().cachedPhotoData[newCachedArray[prevIndex]].folderId,
        get().cachedPhotoData[newCachedArray[prevIndex]].photoId
      )
    } else {
      get().updateActiveIds(null, null)
    }
  },
  /*
  updateLists: async () => {
    get().addLog(`列表更新中...`)
    const response = await getPhotosListsApi(get().user)
    if (response.status === 'success') {
      get().initPhotosByFolder(response.data.folderList)
      get().initLabelList(response.data.labelList)
      get().addLog(`列表更新完成`)
    }
  },
  */

  fixPhotoData: async (folderId) => {
    let updated = false

    // const response = await getAllPhotosDataApi(folderId)
    const photosData = get().collData[folderId].photos
    // if (response.status==='success') {
    if (photosData) {
      // const photosData = response.data
      // await Promise.all(photosData.map(async data => {
      await Promise.all(Object.keys(photosData).map(async photoId => {
        let fixed = false
        // const photoId = data.name
        const data = photosData[photoId]
        // 取得照片尺寸
        if (!data.size || Object.keys(data.size).length===0) {
          const response = await getPhotoSizeApi(data.photoUrl)
          if (response.status==='success') {
            data['size'] = response.data
            fixed = true
            console.log(`${photoId} 新增照片尺寸`)
          } else {
            console.error(`${photoId} 新增照片尺寸失敗`, response.messages)
            return
          }
        }
        // 有標記框
        if (data.regions.length!==0) {
          data.regions = data.regions.map(r => {
            // 沒有相對座標
            if (r.box.xmin==null || r.box.ymin==null || !r.box.xmax || !r.box.ymax) {
              // 檢查標記框座標是否有效
              if (r.box.left>=0 && r.box.top>=0 && r.box.width>0 && r.box.height>0) {
                // 計算相對座標
                fixed = true
                console.log(`${photoId} [${r.label}]新增相對座標`)
                return { ...r, box: { ...r.box, 
                  xmin: clampUnit(r.box.left / data.size.width),
                  ymin: clampUnit(r.box.top / data.size.height),
                  xmax: clampUnit((r.box.left + r.box.width) / data.size.width),
                  ymax: clampUnit((r.box.top + r.box.height) / data.size.height),
                }}
              } else {
                console.error(`${photoId} [${r.label}]座標有誤未修正`, r)
                return r
              }
            } else {
              console.log(`${photoId} [${r.label}]已有相對座標`)
              return r
            }
          })
        } else {
          console.log(`${photoId} 無標記框`)
        }
        if (fixed) {
          // 寫入修正資料
          const response = await updatePhotoDataApi(folderId, photoId, data)
          if (response.status==='success') {
            updated = true
          } else {
            console.error(`${photoId} 寫入資料庫失敗`, response.messages)
          }
        }
      }))
    } else {
      console.error(`照片讀取失敗`)
    }

    if (updated) {
      get().addLog(`[${folderId}] 已修正標記資料，請查看主控台訊息`)
      // get().updateLists() //有修正才更新列表
    } else {
      get().addLog(`[${folderId}] 未修正標記資料，請查看主控台訊息`)
    }
  },

  updatePhotoUrl: async () => {
    const response = await updatePhotoUrlApi(get().collData)
    if (response.status === 'success') {
      get().addLog(`照片連結更新完成`)
      // get().updateLists()
    }
    if (response.status === 'ignore') {
      get().addLog(`照片連結已是最新`)
    }
    if (response.status === 'fail') {
      get().addLog(`照片連結更新失敗`)
    }
  },

}));

export default useStore