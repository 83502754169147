import React, { useState, useEffect } from 'react'
import styles from './main.module.scss'

const ExpandedText = props => {
  const { wrapperRef, scrollRef } = props
  const [expandedTextData, setExpandedTextData] = useState(null)

  useEffect(() => {
    wrapperRef.current && scrollRef.current.addEventListener('mousemove', handleMouseOver)
    scrollRef.current && scrollRef.current.addEventListener('scroll', handleWrapperScroll)
    return () => {
      wrapperRef.current && scrollRef.current.removeEventListener('mousemove', handleMouseOver)
      scrollRef.current && scrollRef.current.removeEventListener('scroll', handleWrapperScroll)
    }
  }, [])

  const handleMouseOver = e => {
    e.preventDefault();

    if(e.target.classList.contains('expandable-text')) {
      var viewportOffset = e.target.getBoundingClientRect()
      setExpandedTextData({
        text: e.target.innerText,
        style: {
          left: viewportOffset.left - 1,
          top: viewportOffset.top - 1,
          display: 'block',
        }
      })
    } else {
      if(expandedTextData) setExpandedTextData(null)
    }
  }

  const handleWrapperScroll = e => {
    setExpandedTextData(null)
  }

  return (
    expandedTextData && 
      <div 
        className={styles.expandedText} 
        style={expandedTextData.style}
      >{expandedTextData.text}</div>
  )
}
export default ExpandedText