import React, { forwardRef, useState, useImperativeHandle } from "react"
import Modal from 'react-bootstrap/Modal'
import logo from '@assets/images/logo-horizontal.svg'

export default forwardRef((props, ref) => {
  const [show, setShow] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  useImperativeHandle(ref, () => ({
    setShow(bol) {
      setShow(bol)
    }
  }))

  return (
    <Modal
      dialogClassName="about"
      backdropClassName="about"
      contentClassName="about"
      show={show}
      onHide={() => setShow(false)}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus>
        <div className="tabs">
          <div className={`tab ${activeTabIndex===0 ? 'active' : ''}`} onClick={e=>setActiveTabIndex(0)}>緣由說明</div>
          <div className={`tab ${activeTabIndex===1 ? 'active' : ''}`} onClick={e=>setActiveTabIndex(1)}>感謝對象</div>
        </div>

        {activeTabIndex===0 && 
          <div className="tab-content">
            <p>黑潮海洋文教基金會自 2010 年展開臺灣「東海岸鯨豚個體辨識（photo ID）計畫」，最初以花紋海豚（或稱瑞氏海豚，學名：Grampus griseus）做為主要種類，並展開「PHOTO-ID 募款計畫—認養你的第一隻海豚！」在持續執行後發現人力進行 photo ID 速度太慢、成效不彰，雖然計畫仍然持續推動，但命名計畫只能宣告暫停。不過很幸運的在 2016 年國立東華大學資訊工程系(所)顏士淨教授及其 AI 工程師連卿閔，提供我們開發人工智慧（artificial intelligence, AI）來加速辨識工作的機會與支援，也讓 AI 辨識花紋海豚的方法開始有了發展的雛形與架構，即便支援計畫結束連卿閔工程師仍以志工的方式繼續協助優化 AI 辨識模型。然而要讓 AI 系統能夠更容易的操作使用，前端網頁的介面也需要隨之開發銜接，故委託 Netabox 接續先前成果，終於在拍攝志工、辨識人員、AI 工程師、贊助企業等多方支持下，終於讓這套 AI 系統順利上線！未來 AI 就會正式開始協助龐大的照片資料辨識，讓我們加速認識花紋海豚在東海岸的族群與生態。</p>
            <p>能夠達成此刻的成果，深深的感謝過往眾多人員與單位的支持！</p>
            <div className="image-wrapper">
              <img src={logo} alt="" />
            </div>
          </div>
        }

        {activeTabIndex===1 && 
          <div className="tab-content">
            <div style={{ margin: '0 auto', maxWidth: '420px' }}>
              <div className="label" style={{ backgroundColor: '#77C664', width: '67px' }}>民間單位</div>
              <p>貿聯國際股份有限公司<br/>財團法人秋圃文教基金會<br/>東元電機股份有限公司<br/>Keep Walking夢想資助計畫<br/>英凡有限公司<br/>多羅滿海上娛樂股份有限公司<br/>中華鯨豚協會<br/>海鯨號<br/>國立東華大學<br/>Netabox</p>
              <div className="label" style={{ backgroundColor: '#F0761D', width: '67px' }}>政府單位</div>
              <p>行政院海洋委員會海洋保育署<br/>交通部觀光局東部海岸國家風景區管理處<br/>花蓮縣政府</p>
              <div className="label" style={{ backgroundColor: '#CBBC35', width: '67px' }}>顧問</div>
              <p>余欣怡</p>
              <div className="label" style={{ backgroundColor: '#65BBEC', width: '67px' }}>拍攝</div>
              <p>江文龍<br/>金磊<br/>邱百合<br/>林思瑩<br/>葉志慧<br/>吳貞儀<br/>區家欣</p>
              <div className="label" style={{ backgroundColor: '#C39DF3', width: '104px' }}>歷年工作人員</div>
              <div style={{ display: 'flex', width: '240px', justifyContent: 'space-between' }}>
                <p>王俐今<br/>何姿樂<br/>李怡萱<br/>林允中<br/>林東良</p>
                <p>林泓旭<br/>林亭君<br/>張卉君<br/>温珮珍<br/>黃文儀</p>
                <p>黃世潔<br/>廖祥惠<br/>歐陽夢芝<br/>鄭佩齡<br/>盧怡安</p>
                <p>賴威任<br/>鍾萍佳</p>
              </div>
              
              <div className="label" style={{ backgroundColor: '#C4CF40', width: '167px' }}>歷年協力人員</div>
              <div style={{ display: 'flex', width: '280px', justifyContent: 'space-between' }}>
                <p>王俐今<br/>王郁傑<br/>王樂怡<br/>甘秋素<br/>江彥瑩</p>
                <p>何孟潔<br/>沈瑞筠<br/>林伊朱<br/>林東良<br/>林思瑩</p>
                <p>胡黛娣<br/>夏尊湯<br/>馬幼蜜<br/>張佳蓉<br/>張意</p>
                <p>曹琇惠<br/>陳玟樺<br/>陳冠任<br/>陳冠榮<br/>陳惠芳</p>
                <p>陳駿宏<br/>黃詠傑<br/>劉君薇<br/>謝宇新</p>
              </div>
              <div className="label" style={{ backgroundColor: '#E8A6BA', width: '67px' }}>AI 開發</div>
              <p>顏士淨. 國立東華大學資訊工程系(所)教授<br/>連卿閔 工程師</p>
            </div>
          </div>
        }
    </Modal>
  )
})