import { useState } from 'react'
import cx from 'classnames'
import styles from './main.module.scss'

import useStore from '@stores'

import IconArrowLeft from "@assets/icons/arrow-left"
import IconArrowRight from "@assets/icons/arrow-right"
import IconListOpen from "@assets/icons/list-open"
import IconClose from '@assets/icons/close'

const Controls = () => {
  const activeIds = useStore(state => state.activeIds)
  const cachedPhotoData = useStore(state => state.cachedPhotoData)
  const clearCachedPhoto = useStore(state => state.clearCachedPhoto)
  const selectPhoto = useStore(state => state.selectPhoto)
  const [open, setOpen] = useState(false)

  const showingData = Object.keys(cachedPhotoData).filter(idPath => cachedPhotoData[idPath].show)

  const getPreviousShowingCachedData = useStore(state => state.getPreviousShowingCachedData)
  const getNextShowingCachedData = useStore(state => state.getNextShowingCachedData)
  const prevIdPath = getPreviousShowingCachedData()
  const nextIdPath = getNextShowingCachedData()

  const handeClickClose = (e, idPath) => {
    e.stopPropagation(); 
    clearCachedPhoto(cachedPhotoData[idPath].folderId, cachedPhotoData[idPath].photoId);
  }

  return (
    <div className={styles.controls}>
      <div className={cx(styles.item, { [styles.disabled]: prevIdPath===null })}>
        <IconArrowLeft onClick={()=>selectPhoto(prevIdPath.split('/')[0], prevIdPath.split('/')[1])} />
      </div>
      <div className={cx(styles.item, { [styles.disabled]: nextIdPath===null })}>
        <IconArrowRight onClick={()=>selectPhoto(nextIdPath.split('/')[0], nextIdPath.split('/')[1])} />
      </div>
      <div className={cx(styles.item, { [styles.active]: open, [styles.disabled]: showingData.length===0 })}>
        <IconListOpen onClick={()=>setOpen(!open)} />
        {open &&
          <div className={styles.photos}>
            {showingData.map((idPath) => {
              const active = activeIds.folder==cachedPhotoData[idPath].folderId && activeIds.photo==cachedPhotoData[idPath].photoId

              return (
                <div 
                  key={`control-tab-${idPath}`} 
                  className={cx(styles.photo, { [styles.active]: active })}
                  onClick={()=>selectPhoto(cachedPhotoData[idPath].folderId, cachedPhotoData[idPath].photoId)}
                >
                  {cachedPhotoData[idPath].photoId}
                  <div
                    className={styles.closeBtn} 
                    onClick={e=>handeClickClose(e, idPath)}
                  >
                    <IconClose />
                  </div>
                </div>
              )
            })}
          </div>
        }
      </div>
    </div>
  )
}
export default Controls