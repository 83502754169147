import React, { useEffect, useRef, useState } from 'react'
import Header from './Header'
import Content from '@components/Content'
import Resizer from '@components/resizer'
import Left from './left'
import Center from './center'
import Right from './right'
import styles from './main.module.scss'
import NewFolderModal from './modals/NewFolderModal'
import DeleteFolderModal from './modals/DeleteFolderModal'
import NewPhotosModal from './modals/NewPhotosModal'
import DeletePhotoModal from './modals/DeletePhotoModal'
import RenamePhotoModal from './modals/RenamePhotoModal'
import RenameFolderModal from './modals/RenameFolderModal'
import ImportTargetDataModal from './modals/ImportTargetDataModal'
import FixPhotoDataModal from './modals/FixPhotoDataModal'
import ExportDatasetModal from './modals/ExportDatasetModal'
import RoleSettingModal from './modals/RoleSettingModal'
import PredictSettingModal from './modals/PredictSettingModal'
import AboutModal from './modals/AboutModal'
import useStore from '@stores'
import labelImgs from '@assets/images/labels/index'
import logo from '@assets/images/logo-bg.png'
import { getConfigApi } from '@scripts/main.js'

const Dashboard = () => {
  const [loading, setLoading] = useState(true)
  const user = useStore(state => state.user)
  // const initPhotosByFolder = useStore(state => state.initPhotosByFolder)
  // const initLabelList = useStore(state => state.initLabelList)
  const monitorCollData = useStore(state => state.monitorCollData)
  const setLabelThreshold = useStore(state => state.setLabelThreshold)
  const setTrainedLabels = useStore(state => state.setTrainedLabels)
  const setPreferences = useStore(state => state.setPreferences)
  const addLog = useStore(state => state.addLog)
  const refLeft = useRef(null)
  const refCenter = useRef(null)
  const refRight = useRef(null)
  const modalsRef = useRef([])
  

  const initApp = async () => {
    /*
    addLog(`檔案導覽載入中...`)
    const response = await getPhotosListsApi(user)
    if (response.status==='success') {
      initPhotosByFolder(response.data.folderList)
      initLabelList(response.data.labelList)
      setLoading(false)
      addLog(`檔案導覽載入完成`)
      // wakeupPredict()
    }
    */

    // 讀取檔案導覽與標記導覽，即時更新
    addLog(`檔案導覽載入中...`)
    monitorCollData((state) => {
      if (state==='init') {
        setLoading(false)
        addLog(`檔案導覽載入完成`)
      }
    })

    // 讀取標記相關設定
    const configRes = await getConfigApi('labelConfig')
    if (configRes.status==='success') {
      // console.log(`讀取設定完成`)
      if (configRes.data.labelThreshold)
        setLabelThreshold(configRes.data.labelThreshold)
      if (configRes.data.trainedLabels)
        setTrainedLabels(configRes.data.trainedLabels)
    } else {
      console.error(`讀取設定失敗`, configRes.messages)
    }
    
    // 讀取使用者偏好設定
    if (user.preferences?.predictConfig !== undefined) {
      setPreferences('predictConfig', user.preferences.predictConfig)
    } else if (configRes.status==='success' && configRes.data.trainedLabels) {
      // 取得最後一個模型名稱，寫入predictConfig
      const lastModel = Object.keys(configRes.data.trainedLabels).sort().pop()
      setPreferences('predictConfig', { score: 0.1, iou: 0.1, topk: 20, model: lastModel })
    }

    // preload images
    setTimeout(() => {
      Object.keys(labelImgs).map(l => Object.keys(labelImgs[l]).map(d => {
        let img = new Image()
        img.src=labelImgs[l][d]
      }))
    }, 600)
  }

  useEffect(() => {
    initApp()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleResize = () => {
    if(refCenter.current) refCenter.current.style.width = (window.innerWidth - refLeft.current.offsetWidth - refRight.current.offsetWidth) + 'px'
  }

  return (
    <>
      <Header modalsRef={modalsRef} refLeft={refLeft} refRight={refRight} />

      <Content
        style={{
          backgroundImage: `url(${logo})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: '30% auto',
        }}
      >
        <div className={styles.wrapper}>
          <div className={styles.sizeWarningCover} />
        
          <Left ref={refLeft} loading={loading} />

          <Resizer 
            type="vertical"
            refBefore={refLeft}  
            refAfter={refCenter}
            beforeMin={200}
            afterMin={300}
          />
          
          <Center ref={refCenter} /> 

          <Resizer 
            type="vertical" 
            refBefore={refCenter}  
            refAfter={refRight}
            beforeMin={300}
            afterMin={220}
          />
          
          <Right ref={refRight} />

        </div>
      </Content>

      <NewFolderModal 
        ref={ref => modalsRef.current.newFolderModal = ref }
        modalsRef={modalsRef}
      />
      <DeleteFolderModal ref={ref => modalsRef.current.deleteFolderModal = ref } />
      <NewPhotosModal 
        ref={ref => modalsRef.current.newPhotosModal = ref }
        modalsRef={modalsRef}
      />
      <DeletePhotoModal ref={ref => modalsRef.current.deletePhotoModal = ref } />
      <RenamePhotoModal ref={ref => modalsRef.current.renamePhotoModal = ref } />
      <RenameFolderModal ref={ref => modalsRef.current.renameFolderModal = ref } />
      <ImportTargetDataModal ref={ref => modalsRef.current.importTargetDataModal = ref } />
      <FixPhotoDataModal ref={ref => modalsRef.current.fixPhotoDataModal = ref }/>
      <ExportDatasetModal ref={ref => modalsRef.current.exportDatasetModal = ref }/>
      <RoleSettingModal ref={ref => modalsRef.current.roleSettingModal = ref } />
      <PredictSettingModal ref={ref => modalsRef.current.predictSettingModal = ref } />
      <AboutModal ref={ref => modalsRef.current.aboutModal = ref }  />
    </>
  )
}

export default Dashboard