import React, { useRef, useEffect, useState } from 'react'
import useStore from '@stores'
import { drawRegion } from './drawers'
import crosshair from '@assets/cursors/crosshair.png'

const Canvas = props => {
  const { config, active, idPath, activeRegions, hovering, panning } = props
  const addManualRegion = useStore(state => state.addManualRegion)
  const updateCachedPhotoActiveRegionIds = useStore(state => state.updateCachedPhotoActiveRegionIds)
  const updateTargetBox = useStore(state => state.updateTargetBox)
  const updateCachedPhotoHovering = useStore(state => state.updateCachedPhotoHovering)
  const hoveringRegion = (activeRegions && hovering) ? activeRegions.filter(r => r.id===hovering.id)[0] : null
  
  const [isDrawing, setIsDrawing] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [box, setBox] = useState({})
  const [movingOffset, setMovingOffset] = useState({ x: null, y: null })
  const [prevCursor, setPrevCursor] = useState('')
  const ref = useRef(null)
  const region = {
    box: {
      left: Math.round(box.left / config.scale),
      top: Math.round(box.top / config.scale),
      width: Math.round(box.width / config.scale),
      height: Math.round(box.height / config.scale),
    }
  }

  const handleMouseDown = e => {
    if (e.button===0) {
      e.preventDefault()
      e.stopPropagation()

      if (!isDrawing && !isEditing) {
        // const mouseX = Math.round(e.nativeEvent.offsetX * config.zoom)
        // const mouseY = Math.round(e.nativeEvent.offsetY * config.zoom)
        const mouseX = e.nativeEvent.offsetX // 圖片座標
        const mouseY = e.nativeEvent.offsetY // 圖片座標
        if (activeRegions && hovering && hoveringRegion) {
          setIsEditing(true)
          if (hovering.part === 'body') {
            setMovingOffset({ // 原圖座標
              x: Math.round(mouseX / config.scale) - hoveringRegion.box.left,
              y: Math.round(mouseY / config.scale) - hoveringRegion.box.top,
            })
          }
        } else {
          setIsDrawing(true)
          setBox({ left: mouseX, top: mouseY }) // 圖片座標
        }
      // } else {
      //   handleFinish()
      }
    } else if (e.button===1) {
      setPrevCursor(ref.current.style.cursor)
      ref.current.style.cursor = 'grabbing'
    }
  }

  const handleMouseMove = e => {
    if ((!isDrawing && !isEditing && !activeRegions) || panning) return
    if (isDrawing || isEditing) {
      e.preventDefault()
      e.stopPropagation()
    }

    // const mouseX = Math.round(e.nativeEvent.offsetX * config.zoom)
    // const mouseY = Math.round(e.nativeEvent.offsetY * config.zoom)
    const mouseX = e.nativeEvent.offsetX // 圖片座標
    const mouseY = e.nativeEvent.offsetY // 圖片座標

    if (activeRegions && !isEditing && !isDrawing) {
      let newPart = null, id = null
      for (let activeRegion of activeRegions) { // 原圖座標
        const cornerMarker = hoveringPosition(activeRegion.box, config.scale, mouseX, mouseY)
        if (cornerMarker) {
          newPart = cornerMarker
          id = activeRegion.id
        }
      }

      if ((!hovering && newPart) || (hovering && (hovering.id !== id || (hovering.part !== newPart)))) {
        switch(newPart) {
          case 'leftTop':
          case 'rightBottom':
            ref.current.style.cursor = 'nwse-resize'
            break
          case 'rightTop':
          case 'leftBottom':
            ref.current.style.cursor = 'nesw-resize'
            break
          case 'body':
            ref.current.style.cursor = 'all-scroll'
            break
          default:
            ref.current.style.cursor = `url(${crosshair}) 16 16, crosshair`
        }
        updateCachedPhotoHovering(idPath, newPart ? { id, part: newPart } : null )
      }
    }
    
    if (isEditing) {
      let newBox = {}  // 原圖座標
      const newX = Math.round(mouseX / config.scale) // 原圖座標
      const newY = Math.round(mouseY / config.scale) // 原圖座標
      if (hovering.part === 'leftTop') {
        newBox.left = newX
        newBox.top = newY
        newBox.width = hoveringRegion.box.left + hoveringRegion.box.width - newX
        newBox.height = hoveringRegion.box.top + hoveringRegion.box.height - newY
      }
      if (hovering.part === 'rightTop') {
        newBox.left = hoveringRegion.box.left
        newBox.top = newY
        newBox.width = newX - newBox.left
        newBox.height = hoveringRegion.box.top + hoveringRegion.box.height - newY
      }
      if (hovering.part === 'leftBottom') {
        newBox.left = newX
        newBox.top = hoveringRegion.box.top
        newBox.width = hoveringRegion.box.left + hoveringRegion.box.width - newX
        newBox.height = newY - newBox.top
      }
      if (hovering.part === 'rightBottom') {
        newBox.left = hoveringRegion.box.left
        newBox.top = hoveringRegion.box.top
        newBox.width = newX - newBox.left
        newBox.height = newY - newBox.top
      }
      if (hovering.part === 'body') {
        const newLeft = newX - movingOffset.x, newTop = newY - movingOffset.y
        newBox.left = (newLeft > 0 && newLeft < ref.current.width / config.scale - hoveringRegion.box.width) ? newLeft : hoveringRegion.box.left
        newBox.top = (newTop > 0 && newTop < ref.current.height / config.scale - hoveringRegion.box.height) ? newTop : hoveringRegion.box.top
        newBox.width = hoveringRegion.box.width
        newBox.height = hoveringRegion.box.height
      }
      updateTargetBox(idPath, hovering.id, { // 原圖座標
        left: (newBox.width < 0) ? (newBox.width + newBox.left) : newBox.left,
        top: (newBox.height < 0) ? (newBox.height + newBox.top) : newBox.top,
        width: Math.abs(newBox.width),
        height: Math.abs(newBox.height),
      })
    }
    if (isDrawing) {
      setBox(prev => ({ // 圖片座標
        ...prev,
        width: mouseX - prev.left,
        height: mouseY - prev.top
      }))
    }
  }

  const handleMouseUp = e => {
    if (e.button===0 && (isDrawing || isEditing)) {
      e.preventDefault()
      e.stopPropagation()
      handleFinish()
    } else if (e.button===1) {
      ref.current.style.cursor = prevCursor
    }
  }

  const handleMouseOut = e => {
    if (isDrawing || isEditing) {
      e.preventDefault()
      e.stopPropagation()
      handleFinish()
    } else if (panning) {
      ref.current.style.cursor = prevCursor
    }
  }

  const handleFinish = () => {
    if (isEditing) {
      setIsEditing(false)
    }
    if (isDrawing) {
      setIsDrawing(false)
      if (box.left && box.top && box.width && box.height) {
        const id = addManualRegion(idPath, { // 原圖座標
          box: {
            left: (region.box.width < 0) ? (region.box.width + region.box.left) : region.box.left,
            top: (region.box.height < 0) ? (region.box.height + region.box.top) : region.box.top,
            width: Math.abs(region.box.width),
            height: Math.abs(region.box.height),
          }
        })
        updateCachedPhotoActiveRegionIds(idPath, id)
      }
      setBox({})
    }
  }

  useEffect(() => {
    if (box.left && box.top && box.width && box.height) {
      const ctx = ref.current.getContext('2d');
      ctx.clearRect(0, 0, ref.current.width, ref.current.height)
      drawRegion(ctx, config.scale, region, 'rgba(255, 255, 0, 1)') // 圖片座標
    }
  }, [box, config.scale])

  useEffect(() => {
    if (!isDrawing) {
      const ctx = ref.current.getContext('2d')
      ctx.clearRect(0, 0, ref.current.width, ref.current.height)
    }
  }, [isDrawing])

  return (
    <canvas
      ref={ref} width={config.width} height={config.height}
      style={{ 
        pointerEvents: active ? 'all' : 'none',
        cursor: `url(${crosshair}) 16 16, crosshair`,
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseOut={handleMouseOut} />
  )
}

export default Canvas

// x、y是圖片座標，box是原圖座標，須轉成圖片座標
const hoveringPosition = (box, scale, x, y) => {
  const cornerWidth = 6;
  const helfWidth = 2;

  const width = Math.round(box.width * scale)
  const height = Math.round(box.height * scale)
  const left = Math.round(box.left * scale)
  const top = Math.round(box.top * scale)
  
  if (x >= (left - helfWidth) && (x <= left+cornerWidth+helfWidth) && (y >= top-helfWidth) && y <= (top+cornerWidth+helfWidth)) {
    return 'leftTop'
  }
  if (x >= left+width-cornerWidth-helfWidth && x <= left+width+helfWidth && y >= top-helfWidth && y <= top+cornerWidth+helfWidth) {
    return 'rightTop'
  }
  if (x >= left-helfWidth && x <= left+cornerWidth+helfWidth && y >= top+height-cornerWidth-helfWidth && y <= top+height+helfWidth) {
    return 'leftBottom'
  }
  if (x >= left+width-cornerWidth-helfWidth && x <= left+width+helfWidth  && y >= top+height-cornerWidth-helfWidth && y <= top+height+helfWidth) {
    return 'rightBottom'
  }
  if (x >= (left - helfWidth) && x <= left+width+helfWidth && (y >= top-helfWidth) && y <= top+height+helfWidth) {
    return 'body'
  }
  return null
}