import React, { forwardRef, useState, useImperativeHandle, useEffect } from "react"
import { deletePhotoApi } from '@scripts/main.js'

import useStore from '@stores'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export default forwardRef((props, ref) => {
  const photosByFolder = useStore(state => state.photosByFolder)
  const addLog = useStore(state => state.addLog)
  const activeIds = useStore(state => state.activeIds)
  const clearCachedPhoto = useStore(state => state.clearCachedPhoto)
  // const updateLists = useStore(state => state.updateLists)
  const folderIds = photosByFolder.map(f => f.id)
  const [show, setShow] = useState(false)
  const [doubleCheckShow, setDoubleCheckShow] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [value, setValue] = useState({ folderId: '', photoId: '' })
  const [messages, setMessages] = useState([])

  useImperativeHandle(ref, () => ({
    setShow(bol) {
      setShow(bol)
    }
  }))

  useEffect(() => {
    if (show) {
      setValue({
        folderId: activeIds.folder || '',
        photoId: activeIds.photo || '',
      })
    } else {
      reset()
      setMessages([])
    }
  }, [show])

  const reset = () => {
    setValue({ folderId: '', photoId: '' })
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    addLog(`[${value.photoId}] 照片刪除中...`)
    const response = await deletePhotoApi(value.folderId, value.photoId)

    if(response.status==='success') {
      clearCachedPhoto(value.folderId, value.photoId)
      // updateLists()
      reset()
      setShow(false)
      addLog(`[${value.photoId}] 照片刪除完成`)
    }
    if(response.status==='fail') {
      setMessages(response.messages)
      addLog(`[${value.photoId}] 照片刪除失敗！`)
    }

    setSubmitting(false)
    setDoubleCheckShow(false)
  }

  const handleOnChange = (e, param) => {
    const value = e.target.value
    if(param === 'folderId') {
      setValue({
        folderId: value,
        photoId: ''
      })
    }
    setValue(prev => ({ ...prev, [param]: value }))
  }

  return (
    <>
      <Modal 
        show={show} 
        onHide={()=>setShow(false)}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        backdrop={!submitting ? true : 'static'}>
        <Modal.Header closeButton={!submitting}>
          <Modal.Title as="h5">刪除照片</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={e => { e.preventDefault(); }}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>目錄名稱</Form.Label>
              <Form.Select size="sm" value={value.folderId} onChange={e => handleOnChange(e, 'folderId')} disabled={submitting}>
                <option value=''>未選取</option>
                {photosByFolder.map((f, i) => <option key={`delete-photo-folder-item-${i}`} value={f.id}>{f.id}</option>)}
              </Form.Select>
            </Form.Group>
            
            {folderIds.indexOf(value.folderId) !==-1 ?
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>檔案名稱</Form.Label>
                <Form.Select size="sm" value={value.photoId} onChange={e => handleOnChange(e, 'photoId')} disabled={submitting}>
                  <option value=''>未選取</option>
                  {photosByFolder[folderIds.indexOf(value.folderId)].photos.map((p, i) => <option key={`delete-photo-item-${i}`}  value={p}>{p}</option>)}
                </Form.Select>
              </Form.Group> : ''}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            disabled={value.folderId==='' || value.photoId==='' || submitting || messages.filter(m => m.type==='error').length!==0} 
            variant="danger" size="sm" onClick={() => setDoubleCheckShow(true)}>
            刪除
          </Button>
        </Modal.Footer>
      </Modal>
    
      <Modal
        show={doubleCheckShow} 
        onHide={()=>setDoubleCheckShow(false)}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        // size="sm"
        backdrop={!submitting ? true : 'static'}
        backdropClassName="z-index-higher"
        className="z-index-higher"
      >
        <Modal.Header closeButton={!submitting}>
          <Modal.Title as="h5">確定執行刪除？</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          您將刪除照片「{value.photoId}」
        </Modal.Body>
        <Modal.Footer>
          <Button 
            disabled={submitting} 
            variant="normal" size="sm" onClick={()=>setDoubleCheckShow(false)}>
            取消
          </Button>
          <Button 
            disabled={submitting} 
            variant="danger" size="sm" onClick={handleSubmit}>
            刪除{submitting ? '中...' : ''}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
})