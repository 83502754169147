import React, { useState } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import useStore from '@stores'
import { roundToNDecimalPlaces } from '@utils/index.js'
import styles from './main.module.scss'
import labelImgs from '@assets/images/labels/index'
import IconManualAddLabel from '@assets/icons/manual-add-label'
import IconPredictRegionVisible from '@assets/icons/predict-region-visible'
import IconPredictRegionInvisible from '@assets/icons/predict-region-invisible'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const Bottom = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const cachedPhotoRegions = useStore(state => state.cachedPhotoRegions)
  const predictingPhoto = useStore(state => state.predictingPhoto)
  const showPredictResults = useStore(state => state.showPredictResults)
  const setShowPredictResults = useStore(state => state.setShowPredictResults)
  const importPredictToTarget = useStore(state => state.importPredictToTarget)
  const activeRegions = hasActiveIdPath() ? cachedPhotoRegions[getActiveIdPath()] : null

  return (
    <div className={styles.rightBottom} style={{ minHeight: '200px', height: '50%', maxHeight: '500px' }}>
      <div className={styles.title}>
        辨識結果
        {activeRegions && activeRegions.predict && 
          activeRegions.predict.length!==0 ?
            <div style={{ position: 'absolute', right: 4, top: 0, whiteSpace: 'nowrap' }}>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 100, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">顯示/隱藏預測框</Tooltip>}
              >
                <div 
                  onClick={()=>showPredictResults ? setShowPredictResults(false) : setShowPredictResults(true)}
                  className={styles.botIcon}
                >
                  {showPredictResults ? <IconPredictRegionVisible /> : <IconPredictRegionInvisible />}
                </div>
              </OverlayTrigger>
            
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 100, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">列入標記框</Tooltip>}
              >
                <div 
                  onClick={()=>importPredictToTarget(getActiveIdPath())}
                  className={styles.botIcon}
                >
                  <IconManualAddLabel />
                </div>
              </OverlayTrigger>
            </div> : ''}
      </div>

      <div className={styles.content}>
        {predictingPhoto[getActiveIdPath()] ? 
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100%', opacity: 0.3 }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> : ''}
        {activeRegions && activeRegions.predict && (
          activeRegions.predict.length!==0 ? 
            activeRegions.predict.map((region, i) => {
              const label = (region.label||'').indexOf('_')!==-1 ? region.label.split('_')[1] : region.label

              return (
                <div key={`predict-data-${region.id}`} className={styles.item}>
                  <PreviewImg label={label} />
                  <p>
                    <span className={styles.value}>@({roundToNDecimalPlaces(region.box.left, 0)}, {roundToNDecimalPlaces(region.box.top, 0)}) + ({roundToNDecimalPlaces(region.box.width, 0)}, {roundToNDecimalPlaces(region.box.height, 0)})</span>
                  </p>
                  <p style={{ margin: 0 }}>
                    <span>Label: <span className={styles.value} style={{ color: '#00D4FF', fontWeight: '600' }}>{region.label}</span>, Score: <span className={styles.value} style={{ color: '#00D4FF', fontWeight: '600' }}>{roundToNDecimalPlaces(region.score, 2)}</span></span>
                  </p>
                </div>
              )
            }) : 
            <div className={styles.item}>
              <div className={styles.checker} />
              無辨識結果
            </div>
        )}
      </div>
    </div>
  )
}

const PreviewImg = props => {
  const { label } = props
  const [checkerDirection, setCheckerDirection] = useState('left')
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const cachedPhotoData = useStore(state => state.cachedPhotoData)
  const updateCachedPhotoPredictPhotoUrl = useStore(state => state.updateCachedPhotoPredictPhotoUrl)
  const imgUrl = labelImgs[label] ? (labelImgs[label][checkerDirection] || labelImgs.temp[checkerDirection]) : labelImgs.temp[checkerDirection]
  const activePhotoData = hasActiveIdPath() ? cachedPhotoData[getActiveIdPath()] : null

  const toggleLabelChecker = (e, label) => {
    e.preventDefault()
    e.stopPropagation()
    let newDirection = null
    if(checkerDirection==='left') newDirection = 'right'
    if(checkerDirection==='right') newDirection = 'left'
    setCheckerDirection(newDirection)

    if(activePhotoData && activePhotoData.predictPhotoUrl) {
      const imgUrl = labelImgs[label] ? (labelImgs[label][newDirection] || labelImgs.temp[newDirection]) : labelImgs.temp[newDirection]
      updateCachedPhotoPredictPhotoUrl(getActiveIdPath(), imgUrl)
    }
  }

  const handleImgOnClick = (e, url) => {
    updateCachedPhotoPredictPhotoUrl(getActiveIdPath(), url)
  }

  return (
    <div onClick={e => handleImgOnClick(e, imgUrl)} className={styles.checker} style={{ backgroundImage: `url(${imgUrl})` }}>
      <div onClick={e => toggleLabelChecker(e, label)} className={styles.btn}>{`< >`}</div>
    </div>
  )
}

export default Bottom