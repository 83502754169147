
import React, { useState, forwardRef, useEffect, useMemo, useRef } from 'react'
import FileList from './FileList'
import LabelFileList from './LabelFileList'
import Spinner from 'react-bootstrap/Spinner'

import cx from 'classnames'
import styles from './main.module.scss'

const tabs = [{
  label: '檔案導覽'
}, {
  label: '標記導覽'
}]

const Left = forwardRef((props, ref) => {
  const { loading } = props
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const renderTabs = useMemo(() => (
    <div className={styles.tabWrapper}>
      {tabs.map((tab, i) => 
        <Tab 
          key={`left-tab-${i}`}
          isActive={i===activeTabIndex}
          onClick={()=>setActiveTabIndex(i)} 
          label={tab.label} />)}
    </div>
  ), [activeTabIndex])

  return (
    <div ref={ref} className={styles.leftWrapper}>
      {renderTabs}

      <TabContent loading={loading} active={activeTabIndex===0}>
        <FileList active={activeTabIndex===0} />   
      </TabContent>

      <TabContent loading={loading} active={activeTabIndex===1}>
        <LabelFileList active={activeTabIndex===1} />
      </TabContent>
    </div>
  )
})

export default Left

const Tab = props => {
  const { label, onClick, isActive } = props

  const tabClassName = cx(styles.tab, {
    [styles.tabActive]: isActive,
  });

  return (
    <div className={tabClassName} onClick={onClick}>
      {label}
    </div>
  )
}

const TabContent = props => {
  const { active, children, loading } = props
  const ref = useRef()

  return (
    <div ref={ref} className={styles.contentWrapper} style={{ display: active?'block':'none' }}>
      {loading ? 
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%', opacity: 0.3 }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div> 
      : React.cloneElement(children, { scrollRef: ref })}
    </div>
  )
}