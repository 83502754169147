const required = (value) => {
  if ((value || '').trim() !== '') {
    return {passed: true, messages: null}
  } else {
    return {passed: false, messages: [{type: 'error', content: '此欄位必填。'}]}
  }
}

const fileNameFormat = (value) => {
  if ((value || '').trim() !== '') {
    const rg1=/^[^\\/:\*\?"<>\|]+$/  // forbidden characters \ / : * ? " < > |
    const rg2=/^\./  // cannot start with dot (.)
    const rg3=/^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i  // forbidden file names
    // let regExp = /^(([^<>()/[\]\\.,;:\s@\"]+(\.[^<>()/[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if(!rg1.test(value)) return {passed: false, messages: [{type: 'error', content: '請勿使用特殊符號 \ / : * ? " < > |'}]}
    if(rg2.test(value)) return {passed: false, messages: [{type: 'error', content: '請勿以 . 作為檔名開頭'}]}
    if(rg3.test(value)) return {passed: false, messages: [{type: 'error', content: '請勿使用系統保留字'}]}
  }
  return {passed: true, messages: null}
}

const numberFormat = (value, min, max) => {
  if (value !== '') {
    // const rg1 = /^\d+$/
    const rg1 = /^\d+(\.\d+)?$/
    if (!rg1.test(value)) return {passed: false, messages: [{type: 'error', content: '請輸入數字'}]}
    if (min !== '' && value < min) return {passed: false, messages: [{type: 'error', content: `請輸入${min}以上的數字`}]}
    if (max !== '' && value > max) return {passed: false, messages: [{type: 'error', content: `請輸入${max}以下的數字`}]}
  }  else {
    return {passed: false, messages: [{type: 'error', content: '此欄位必填'}]}
  }
  return {passed: true, messages: null}
}

export default {
  fileNameFormat,
  numberFormat,
  required,
}
