import React, { useEffect, useState } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import IconDeleteLabel from '@assets/icons/delete'

import useStore from '@stores'

import { roundToNDecimalPlaces } from '@utils/index'

// import trainedLabels from '@data/trained-label'

import cx from 'classnames'
import styles from './main.module.scss'

import IconSaveWarning from '@assets/icons/save-warning'

const Top = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const cachedPhotoRegions = useStore(state => state.cachedPhotoRegions)
  const userBehaviorMode = useStore(state => state.userBehaviorMode)
  const activeRegions = hasActiveIdPath() ? cachedPhotoRegions[getActiveIdPath()] : null
  const hasUnsavedRegions = activeRegions && (activeRegions.targetManual.length!==0 || activeRegions.targetPredict.length!==0 || JSON.stringify(activeRegions.targetStorage)!==activeRegions.originTargetRegionsString)
  
  return (
    <div className={styles.rightTop} style={{ minHeight: '200px', height: '50%', maxHeight: '500px' }}>
      <div className={styles.title}>
        標記內容
        {/* {hasUnsavedRegions ? <span style={{ fontSize: '12px', opacity: 0.5 }}> - 已編輯</span> : ''} */}
        {hasUnsavedRegions &&
          <div style={{ position: 'absolute', right: 4, top: 0, whiteSpace: 'nowrap' }}>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 100, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">內容尚未儲存</Tooltip>}
            >
              <div 
                // onClick={()=>importPredictToTarget(getActiveIdPath())}
                className={styles.topIcon}
              >
                <IconSaveWarning />
              </div>
            </OverlayTrigger>
          </div>
        }
      </div>
      
      <div className={cx(styles.content, styles[`mode_${userBehaviorMode}`] )}>
        {activeRegions && activeRegions.targetStorage && 
          activeRegions.targetStorage.map((region, i) => 
          <TargetRegionItem 
            key={`target-data-targetStorage-${region.id}`} 
            type={'targetStorage'} 
            region={region} 
            active={activeRegions.activeIds.indexOf(region.id)!==-1} />)}

        {activeRegions && activeRegions.targetManual && 
          activeRegions.targetManual.map((region, i) => 
          <TargetRegionItem 
            key={`target-data-targetManual-${region.id}`} 
            type={'targetManual'} 
            region={region} 
            active={activeRegions.activeIds.indexOf(region.id)!==-1} />)}
        
        {activeRegions && activeRegions.targetPredict && 
          activeRegions.targetPredict.map((region, i) => 
          <TargetRegionItem 
            key={`target-data-targetPredict-${region.id}`} 
            type={'targetPredict'} 
            region={region} 
            active={activeRegions.activeIds.indexOf(region.id)!==-1} />)}
      </div>
    </div>
  )
}

export default Top

const TargetRegionItem = props => {
  const deleteTargetRegion = useStore(state => state.deleteTargetRegion)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const updateTargetLabel = useStore(state => state.updateTargetLabel)
  const updateCachedPhotoActiveRegionIds = useStore(state => state.updateCachedPhotoActiveRegionIds)
  const trainedLabels = useStore(state => state.trainedLabels)
  const preferences = useStore(state => state.preferences)
  const modelName = preferences.predictConfig.model
  const { type, region, active } = props
  const [labelValue, setLabelValue] = useState(region.label)

  useEffect(() => {
    updateTargetLabel(getActiveIdPath(), type, region.id, labelValue)
  }, [labelValue])

  useEffect(() => {
    setLabelValue(region.label || '')
  }, [region.label])

  const handleOnClick = e => {
    e.preventDefault()
    e.stopPropagation()
    updateCachedPhotoActiveRegionIds(getActiveIdPath(), region.id)
  }

  const handleOnClose = e => {
    e.preventDefault()
    e.stopPropagation()
    deleteTargetRegion(getActiveIdPath(), type, region.id)
  }

  return (
    <div 
      className={cx(styles.item, styles[type], { [styles.active]: active, [styles.trained]: trainedLabels[modelName].indexOf(region.label)!==-1 })} 
      onClick={handleOnClick}>
      <div
        className={styles.closeButton}
        data-region-status={active}
        onClick={handleOnClose}
      >
        <IconDeleteLabel />
      </div>
      {/* {region.id} */}
      <p style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>Region: <span className={styles.value}>@({roundToNDecimalPlaces(region.box.left, 0)}, {roundToNDecimalPlaces(region.box.top, 0)}) + ({roundToNDecimalPlaces(region.box.width, 0)}, {roundToNDecimalPlaces(region.box.height, 0)})</span></span>
      </p>
      <p style={{ display: 'flex', justifyContent: 'space-between', margin: 0 }}>
        <span>Label: 
          <input 
            type="text" 
            style={{ width: '100px', lineHeight: '20px', marginLeft: '8px' }} 
            value={labelValue} 
            onChange={e=>setLabelValue(e.target.value)}
            onClick={e => { e.preventDefault(); e.stopPropagation() }}
          />
        </span>
      </p>
    </div>
  )
}