export const roundToNDecimalPlaces = (v, n=2) => {
  return Math.round(v * Math.pow(10, n)) / Math.pow(10, n);
}

export const toPercetage = (v, n=2) => {
  return roundToNDecimalPlaces(v*100, n) + '%'
}

export const smoothlyScrollTo = (element, to, duration) => {
  if (element) {
    const start = element.scrollLeft
    const change = to - start
    let currentTime = 0
    const increment = 20

    const animateScroll = () => {
      currentTime += increment
      const val = Math.easeInOutQuad(currentTime, start, change, duration)
      element.scrollLeft = val
      if (currentTime < duration) {
        setTimeout(animateScroll, increment)
      }
    }
    animateScroll()
  }
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2
  if (t < 1) return c/2*t*t + b
  t--
  return -c/2 * (t*(t-2) - 1) + b
}


export const MakeId = (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const charactersLength = characters.length
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const clamp = (v, min, max) => {
  return Math.min(Math.max(v, min), max)
}

export const clampUnit = (v) => {
  return Math.min(Math.max(v, 0.0), 1.0)
}