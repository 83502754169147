import firebaseApp from '../app/firebaseApp'
import firebase from 'firebase'
import axios from 'axios'

const auth = firebase.auth(firebaseApp)
const firestore = firebase.firestore()
const storage = firebase.storage()
// const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()
const PHOTO_PATH = 'photos'

export const getUserApi = async user => {
  const docRef = firestore.collection('users').doc(user.uid)
  try {
    const userDoc = await docRef.get()
    let userData = null
    if(!userDoc.exists)
      userData = await setUserApi(user.uid, user.email, user.email.split('@')[0])
    else
      userData = userDoc.data()

    return {
      status: 'success',
      data: userData
    }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}
/*
// 讀取所有設定
export const getAllConfigApi = async () => {
  const colRef = firestore.collection('configs')
  let configs = {}
  try {
    const querySnapshot = await colRef.get()
    querySnapshot.docs.map((doc) => configs[doc.id] = doc.data())
    return { status: 'success', data: configs }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `讀取設定錯誤` }]
    }
  }
}
*/
// 讀取設定
export const getConfigApi = async (configId) => {
  const docRef = firestore.collection('configs').doc(configId)
  try {
    const userDoc = await docRef.get()
    return { status: 'success', data: userDoc.data() }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `讀取設定錯誤` }]
    }
  }
}

// 寫入設定
export const setConfigApi = async (configId, data) => {
  const docRef = firestore.collection('configs').doc(configId)
  try {
    await docRef.set(data, { merge: true })
    return { status: 'success' }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `寫入設定錯誤` }]
    }
  }
}

// 寫入使用者偏好設定
export const setPreferencesApi = async (uid, data) => {
  const docRef = firestore.collection('users').doc(uid)
  try {
    await docRef.set({ preferences: data }, { merge: true })
    return { status: 'success' }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}
/*
// 依標記順序導覽 (按標記/目錄/檔案排序)
export const getPhotosListsApi = async user => {
  try {
    const folderIds = await getFoldersApi(user)
    let defaultFolder = {}
    folderIds.map(fi => defaultFolder[fi] = [])
    
    const photosRef = firestore.collectionGroup('photos')
    const snapshot = await photosRef.get()

      let byFolders = {...defaultFolder}, byLabels = {} // photoData = {}
      snapshot.forEach((doc) => {
        const regions = doc.data().regions
        const folderId = doc.ref.parent.parent.id
        const photoId = doc.id

        if(byFolders[folderId]) byFolders[folderId].push(photoId)

        if(Array.isArray(regions) && folderIds.indexOf(folderId) !== -1) {
          regions.map((region) => {
            if(!byLabels[region.label]) byLabels[region.label] = {}
            if(!byLabels[region.label][folderId]) byLabels[region.label][folderId] = []
            if(!byLabels[region.label][folderId][photoId]) byLabels[region.label][folderId][photoId] = []
            byLabels[region.label][folderId][photoId].push(region.box)
          })
        }
        // 暫存使用者能存取的所有照片資料
        // if (folderIds.indexOf(folderId) !== -1) {
        //   photoData[`${folderId}/${photoId}`] = doc.data()
        // }
      })

      const folderList = Object.keys(byFolders).map(fi => ({
        id: fi,
        photos: byFolders[fi]
      }))
      const labelList = Object.keys(byLabels).sort((a, b) => a - b).map(ln => ({
        name: ln,
        folders: Object.keys(byLabels[ln]).map(fi => ({
          id: fi,
          photos: Object.keys(byLabels[ln][fi]).map(pi => ({
            id: pi,
            boxs: byLabels[ln][fi][pi]
          }))
        }))
      }))

      return {
        status: 'success',
        data: { folderList, labelList }
        // data: { folderList, labelList, photoData }
      }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}
*/
/*
export const getFoldersApi = async user => {
  try {
    const colRef = firestore.collection('folders')
    const querySnapshot = await colRef.get()

    // 管理者與維護者可開啟所有目錄，編輯者只能開啟自己的目錄
    return querySnapshot.docs
      .filter((doc) => (user.role==='admin') || (user.role==='maintainer') || (user.role==='editor' && user.uid===doc.data().uid))
      .map(doc => doc.id)
  } catch(error) {
    console.error(error)
  }
}
*/

// 即時更新所有目錄資料
export const monitorFoldersApi = (callback) => {
  const foldersRef = firestore.collection('folders')
  foldersRef.onSnapshot((snapshot) => {
    let foldersData = []
    snapshot.docChanges().map((change) => {
      foldersData.push({
        type: change.type,
        folderId: change.doc.id,
        //data: change.doc.data(),
        uid: change.doc.data().uid,
      })
    })
    callback(foldersData)
  }, (error) => {
    console.error(error)
  })
}

// 即時更新所有照片資料
export const monitorPhotosApi = (callback) => {
  const photosRef = firestore.collectionGroup('photos')
  photosRef.onSnapshot((snapshot) => {
    let photosData = []
    snapshot.docChanges().map((change) => {
      photosData.push({
        type: change.type,
        folderId: change.doc.ref.parent.parent.id,
        photoId: change.doc.id,
        //data: change.doc.data(),
        photoUrl: change.doc.data().photoUrl,
        size: change.doc.data().size,
        regions: change.doc.data().regions,
      })
    })
    callback(photosData)
  }, (error) => {
    console.error(error)
  })
}

// 替未登入過的使用者建立資料
const setUserApi = async (uid, email, name) => {
  try {
    const docRef = firestore.collection('users').doc(uid)
    const data = {
      uid: uid,
      name: name,
      email: email,
      role: 'editor', // 預設為 editor
    }
    await docRef.set(data)
    return data
  } catch (error) {
    console.error(error)
  }
}

export const updateUserRoleApi = async (uid, newRole) => {
  const docRef = firestore.collection('users').doc(uid)
  const data = {
    role: newRole,
  }
  try {
    await docRef.set(data, { merge: true })
    return { status: 'success' }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}

export const getUserListApi = async () => {
  const colRef = firestore.collection('users')
  try {
    const querySnapshot = await colRef.get()
    // 只回傳使用者profile，排除其他資料
    const profiles = querySnapshot.docs.map(doc => ({
      uid: doc.data().uid,
      name: doc.data().name,
      email: doc.data().email,
      role: doc.data().role
    })).filter(u => u.role!=='maintainer') // 排除維護者
    return {
      status: 'success',
      // data: response.docs.map(doc =>  doc.data()).filter(u => u.role!=='maintainer')  // 排除維護者
      data: profiles
    }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤...` }]
    }
  }
}
/*
// 列出某目錄的所有照片
const getPhotosByFolderApi = async (folderId) => {
  const colRef = firestore.collection('folders').doc(folderId).collection('photos')
  try {
    const querySnapshot = await colRef.get()
    return {
      status: 'success',
      data: querySnapshot.docs.map((doc) => doc.id)
    }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤...` }]
    }
  }
}
*/
/*
// 讀取某目錄的所有照片資料
export const getAllPhotosDataApi = async (folderId) => {
  const colRef = firestore.collection('folders').doc(folderId).collection('photos')
  try {
    const querySnapshot = await colRef.get()
    return {
      status: 'success',
      data: querySnapshot.docs.map((doc) => doc.data())
    }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤...` }]
    }
  }
}
*/
/*
// 讀取單張照片資料
export const getPhotoDataApi = async (folderId, photoId) => {
  try {
    const docRef = firestore.collection('folders').doc(folderId).collection('photos').doc(photoId)
    const docSnap = await docRef.get()
    return {
      status: 'success',
      data: docSnap.data()
    }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤...` }]
    }
  }
}
*/
// 建立單個目錄資料
export const addFolderApi = async (folderId) => {
  try {
    const docRef = firestore.collection('folders').doc(folderId)
    const data = {
      photos: {},
      name: folderId,
      uid: auth.currentUser.uid,
      // timestamp: serverTimestamp
    }
    // const docSnap = await docRef.get()
    // if (!docSnap.exists) {
      await docRef.set(data)
      return { status: 'success' }
    // } else {
    //   return {
    //     status: 'fail',
    //     messages: [{ type: 'error', content: `目錄名稱已存在，請變更名稱` }]
    //   }
    // }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}

export const deleteFolderApi = async folderId => {
  try {
    const folderRef = firestore.collection('folders').doc(folderId)

    // const snapshot = await folderRef.get()
    // if (snapshot.exists) {
      await folderRef.delete()
      return { status: 'success' }
    // } else {
    //   return { status: 'fail', messages: [{ type: 'error', content: `資料夾不存在` }] }
    // }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}

export const deletePhotoApi = async (folderId, photoId) => {
  try {
    const storeRef = firestore.collection('folders').doc(folderId).collection('photos').doc(photoId)

    // const snapshot = await storeRef.get()
    // if(snapshot.exists) {
      await storeRef.delete()
    // }
    const storageRef = storage.ref(`${PHOTO_PATH}/${folderId}/${photoId}`)
    return await storageRef.delete()
      .then(() => {
        return { status: 'success' }
      }).catch((error) => {
        console.error(error)
        return {
          status: 'fail',
          messages: [{ type: 'error', content: `發生了未知的錯誤` }]
        }
      })
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}

// 更新(某目錄的)單張照片資料
export const updatePhotoDataApi = async (folderId, photoId, data) => {
  try {
    const docRef = firestore.collection('folders').doc(folderId).collection('photos').doc(photoId)
    // data['timestamp'] = serverTimestamp

    await docRef.set(data, { merge: true })
    return { status: 'success' }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}

// 建立或更新(某目錄的)單張照片資料的標記框
export const updatePhotoRegionsApi = async (folderId, photoId, regions) => {
  try {
    regions = regions.map(r => ({
      box: {...r.box},
      label: r.label || ''
    }))
    const docRef = firestore.collection('folders').doc(folderId).collection('photos').doc(photoId)
    const data = {
      regions,
      // timestamp: serverTimestamp
    }

    await docRef.set(data, { merge: true })
    return { status: 'success' }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}

// 辨識照片中的海豚背鰭
export const identifyPhotoApi = async (params) => {
  try {
    // clearTimer()
    const config = { params: params }
    const url = '/predict'
    // const url = 'https://photo-id-app.netabox.tw/predict'

    const response = await axios.get(url, config)
    // setTimer()
    return {
      status: 'success',
      data: response.data
    }
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}

// 讀取照片尺寸
export const getPhotoSizeApi = (photoUrl) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = photoUrl
    image.onload = () => {
		  resolve({
        status: 'success',
        data: { width: image.naturalWidth, height: image.naturalHeight }
      })
    }
    image.onerror = () => {
      reject({
        status: 'fail',
        messages: [{ type: 'error', content: `發生了未知的錯誤` }]
      })
    }
  }).catch((error) => {
    console.error(error)
    return error
  })
}

const getImageSize = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      const image = new Image()
      image.src = fileReader.result
      image.onload = () => {
	      resolve({ width: image.naturalWidth, height: image.naturalHeight })
      }
      image.onerror = () => {
        reject(`${file.name} 無法載入`)
      }
    }
  }).catch((error) => {
    console.error(error)
    return {}
  })
}

// 上傳單張照片
export const addPhotoApi = async (folderId, rawPhoto, regions=[], rewrite=false, dom=null) => {
  try {
    const photo = new File([rawPhoto], (rawPhoto.uploadName || rawPhoto.name), { type: rawPhoto.type })
    const storeRef = firestore.collection('folders').doc(folderId).collection('photos').doc(photo.name)

    const filePath = `${PHOTO_PATH}/${folderId}/${photo.name}`
    const storageRef = storage.ref(filePath)

    // const snapshot = await storeRef.get()
    // if(!snapshot.exists) {
    if(rewrite) {
      await storeRef.delete()
      await storageRef.delete()
        .then(() => {
          return { status: 'success' }
        })
        .catch((error) => {
          console.error(error)
          return {
            status: 'fail',
            messages: [{ type: 'error', content: `發生了未知的錯誤` }]
          }
        })
    }
    const uploadTask = storageRef.put(photo)

    return await new Promise(async (resolve, reject) => {
      await uploadTask.on(
        'state_changed', 
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          dom.style.width = progress.toFixed(0) + '%'
          dom.innerHTML = progress.toFixed(0) + '%'
        },
        (error) => {
          reject({
            status: 'fail',
            messages: [{ type: 'error', content: `發生了未知的錯誤` }]
          })
        },
        async () => { 
          const [url, size] = await Promise.all([
            uploadTask.snapshot.ref.getDownloadURL(),
            getImageSize(photo)  // 讀取照片尺寸
          ])
          const data = {
            name: photo.name,
            // storageUri: filePath,
            photoUrl: url,
            size: size,
            regions: regions,
            // timestamp: serverTimestamp
          }
          await storeRef.set(data)
          resolve({
            status: 'success'
          })
        }
      )
    })
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}

// 更名照片 Storage 名稱
const renamePhotoStorageApi = async (folder, srcPhoto, destPhoto) => {
  const data = {
    srcFile: `photos/${folder}/${srcPhoto}`,
    destFile: `photos/${folder}/${destPhoto}`,
  }
  const url = '/renamePhoto'

  const response = await axios.post(url, data)
  if(response.status===200) {
    return { status: 'success' }
  } else {
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}

// 更名目錄 Storage 路徑
const renameFolderStorageApi = async (srcFolder, destFolder) => {
  const data = {
    srcFolder: `photos/${srcFolder}`,
    destFolder: `photos/${destFolder}`,
  }
  const url = '/renameFolder'

  const response = await axios.post(url, data)
  if(response.status===200) {
    return { status: 'success' }
  } else {
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}

// 更新單張照片名稱
export const renamePhotoApi = async (folderId, photoId, newPhotoId, photoData) => {
  try {
    const preStoreRef = firestore.collection('folders').doc(folderId).collection('photos').doc(photoId)
    const newStoreRef = firestore.collection('folders').doc(folderId).collection('photos').doc(newPhotoId)

    // const snapshot = await newStoreRef.get()
    // if(!snapshot.exists) {
    const response = await renamePhotoStorageApi(folderId, photoId, newPhotoId)
    if(response.status!=='success') {
      return {
        status: 'fail',
        messages: [{ type: 'error', content: `發生了未知的錯誤` }]
      }
    }
  
    // storage 搬移 done
    const filePath = `${PHOTO_PATH}/${folderId}/${newPhotoId}`
    let updateUrls = {}
    const newStorageRef = storage.ref(filePath)
    return await newStorageRef.getDownloadURL()
      .then(async url => {
        //const docSnap = await preStoreRef.get()
        const data = {
          name: newPhotoId,
          // storageUri: filePath,
          photoUrl: url,
          // size: docSnap.data().size || {},
          size: photoData.size || null,
          // regions: docSnap.data().regions,
          regions: photoData.regions,
          // timestamp: serverTimestamp
        }
        updateUrls[`${folderId}/${newPhotoId}`] = url
        await newStoreRef.set(data)
        await preStoreRef.delete()
        return { status: 'success', data: updateUrls }
      })
      .catch(error => {
        console.error(error)
        return {
          status: 'fail',
          messages: [{ type: 'error', content: `發生了未知的錯誤` }]
        }
      })
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}

// 更新目錄名稱
export const renameFolderApi = async (folderId, newFolderId, photosData) => {
  try {
    const newFolderStoreRef = firestore.collection('folders').doc(newFolderId)
    /*
    const snapshot = await newFolderStoreRef.get()
    if(snapshot.exists) {
      return ({
        status: 'fail',
        messages: [{ type: 'error', content: `目錄名稱已存在，請變更名稱` }]
      })
    }
    */
    // let response
    const response = await renameFolderStorageApi(folderId, newFolderId)
    if(response.status!=='success') return ({ status: 'fail', messages: [{ type: 'error', content: `發生了未知的錯誤` }] })

    // storage 搬移 done
    // response = await getPhotosByFolderApi(folderId)
    // if(response.status!=='success') return ({ status: 'fail', messages: [{ type: 'error', content: `發生了未知的錯誤` }] })
    // const photoIds = response.data

    // build new folders and photos on store
    // folder
    const data = {
      photos: {},
      name: newFolderId,
      uid: auth.currentUser.uid,
      // timestamp: serverTimestamp
    }
    await newFolderStoreRef.set(data)
    let updateUrls = {}
    // photos
    await Promise.all(Object.keys(photosData).map(async photoId => {
      // const prePhotoStoreRef = firestore.collection('folders').doc(folderId).collection('photos').doc(photoId)
      const newPhotoStoreRef = firestore.collection('folders').doc(newFolderId).collection('photos').doc(photoId)

      const filePath = `${PHOTO_PATH}/${newFolderId}/${photoId}`
      const newPhotoStorageRef = storage.ref(filePath)
      await newPhotoStorageRef.getDownloadURL()
      .then(async url => {
        // const docSnap = await prePhotoStoreRef.get()
        const data = {
          name: photoId,
          // storageUri: filePath,
          photoUrl: url,
          // size: docSnap.data().size || {},
          size: photosData[photoId].size || null,
          // regions: docSnap.data().regions,
          regions: photosData[photoId].regions,
          // timestamp: serverTimestamp
        }
        updateUrls[`${newFolderId}/${photoId}`] = url
        await newPhotoStoreRef.set(data)
      })
      .catch(error => {
        console.error(error)
      })
    }))

    // delete old folder and photos on store
    // photos
    await Promise.all(Object.keys(photosData).map(async photoId => {
      const prePhotoStoreRef = firestore.collection('folders').doc(folderId).collection('photos').doc(photoId)
      await prePhotoStoreRef.delete()
    }))
    // folder
    await deleteFolderApi(folderId)
    
    return ({ status: 'success', data: updateUrls })
  } catch(error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}

// 根據Storage檔案，更新所有照片的連結
export const updatePhotoUrlApi = async (collData) => {
  try {
    const rootRef = storage.ref(`${PHOTO_PATH}`)
    const root = await rootRef.listAll()
    const prefixes = root.prefixes
    let update = false

    await Promise.all(prefixes.map(async prefix => {
      const folderId = prefix.name
      const folderStorageRef = storage.ref(`${PHOTO_PATH}/${folderId}`)
      const folder = await folderStorageRef.listAll()
      const items = folder.items
      // const folderStoreRef = firestore.collection('folders').doc(folderId)
      // const docSnap = await folderStoreRef.get()
      // if (!docSnap.exists) {
      if (!collData[folderId]) {
        await addFolderApi(folderId)
        console.log(folderId, '新增目錄資料')
      }

      await Promise.all(items.map(async item => {
        const photoId = item.name
        const photoPath = `${PHOTO_PATH}/${folderId}/${photoId}`
        const photoStorageRef = storage.ref(photoPath)
        const url = await photoStorageRef.getDownloadURL()
        const photoStoreRef = firestore.collection('folders').doc(folderId).collection('photos').doc(photoId)
        // const docSnap = await photoStoreRef.get()
        const photoData = collData[folderId].photos[photoId]
        let data = {}

        // if (!docSnap.exists) {
        if (!photoData) {
          const response = await getPhotoSizeApi(url)
          data = {
            name: photoId,
            // storageUri: photoPath,
            photoUrl: url,
            size: response.data, // null,
            regions: [],
            // timestamp: serverTimestamp
          }
          await photoStoreRef.set(data)
          update = true
          console.log(photoId, '新增照片資料')
        // } else if (docSnap.data().photoUrl !== url) {
        } else if (photoData.photoUrl !== url) {
          data = {
            photoUrl: url,
            // timestamp: serverTimestamp
          }
          await photoStoreRef.set(data, { merge: true })
          update = true
          console.log(photoId, '更新照片連結')
        // } else {
        //   console.log(photoId, '不須更新')
        }
      }))
    }))
    if (update) {
      return { status: 'success' }
    } else {
      return { status: 'ignore' }
    }
  } catch (error) {
    console.error(error)
    return {
      status: 'fail',
      messages: [{ type: 'error', content: `發生了未知的錯誤` }]
    }
  }
}

/*
let timerId

function setTimer() {
  timerId = setTimeout(wakeupPredict, 60000*15)
}

function clearTimer() {
  clearTimeout(timerId)
}

export const wakeupPredict = async () => {
  const config = {
    params: { stat: 1 },
  }
  const url = '/predict'
  try {
    const response = await axios.get(url, config)
    // console.log('wakeup', response.data, new Date())
    setTimer()
  } catch(error) {
    console.error(error)
  }
}
*/