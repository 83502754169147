import React, { useEffect, useCallback } from 'react'
import firebaseApp from '../../app/firebaseApp'
import firebase from 'firebase'

import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import NavDropdown from 'react-bootstrap/NavDropdown'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import { Location } from '@reach/router'
import useStore from '@stores'

import IconNewFolder from '@assets/icons/new-folder'
import IconRenameFolder from '@assets/icons/rename-folder'
import IconDeleteFolder from '@assets/icons/delete-folder'
import IconUploadPhoto from '@assets/icons/upload-photo'
import IconRenamePhoto from '@assets/icons/rename-photo'
import IconDeletePhoto from '@assets/icons/delete-photo'
import IconSetting from '@assets/icons/setting'
import IconEditMode from '@assets/icons/edit-mode'
import IconViewMode from '@assets/icons/view-mode'
import IconAutoAddLabel from '@assets/icons/auto-add-label'
import IconManualAddLabel from '@assets/icons/manual-add-label'
import IconSave from '@assets/icons/save'
import IconDeleteLabel from '@assets/icons/delete'
import IconSelectAllRegions from '@assets/icons/select-all-regions'
import IconLabelColor from '@assets/icons/color'
import IconImport from '@assets/icons/import'
import IconExport from '@assets/icons/export'
import IconRefresh from '@assets/icons/refresh'
import IconFixData from '@assets/icons/fix-data'
import IconExportDataset from '@assets/icons/export-dataset'
import IconArrowLeft from '@assets/icons/arrow-left'
import IconArrowRight from '@assets/icons/arrow-right'
import IconClose from '@assets/icons/close'
// import IconSizeToBigger from '@assets/icons/size-to-bigger'
// import IconSizeToSmaller from '@assets/icons/size-to-smaller'
import IconSizeToOrigin from '@assets/icons/size-to-origin'
import IconPredict from '@assets/icons/predict'
import IconDirection from '@assets/icons/direction'
import IconAbout from '@assets/icons/about'

import CONS from '@stores/constant'
import styles from './main.module.scss'
// import dayjs from 'dayjs'

const auth = firebase.auth(firebaseApp)

const Header = props => {
  const user = useStore(state => state.user)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const predictPhoto = useStore(state => state.predictPhoto)
  const displayName = user && user.name

  const appSignOut = () => {
    auth.signOut()
    window.location.reload()
  }  

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  const handleKeyDown = (e) => {
    if (e.key==='F8' && hasActiveIdPath()) {
      predictPhoto(getActiveIdPath())
    }
  }

  return (
    <>
      <Navbar 
        bg="secondary" variant="dark" 
        style={{ zIndex: 999, boxShadow: '0 .25rem .25rem rgba(0, 0, 0, .15)', padding: '2px 8px' }}
      >
        <Container fluid>
          <Nav className={styles.left}>
            <Navbar.Brand>
              Photo-ID
            </Navbar.Brand>
          </Nav>
          <div className={styles.center}>
            <Nav>
              <NavDropdown title="檔案" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={()=>props.modalsRef.current.newFolderModal.setShow(true)}>
                  <IconNewFolder/>
                  新增目錄
                </NavDropdown.Item>
                <NavDropdown.Item onClick={()=>props.modalsRef.current.renameFolderModal.setShow(true)}>
                  <IconRenameFolder />
                  更名目錄
                </NavDropdown.Item>
                <NavDropdown.Item onClick={()=>props.modalsRef.current.deleteFolderModal.setShow(true)}>
                  <IconDeleteFolder />
                  刪除目錄
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={()=>props.modalsRef.current.newPhotosModal.setShow(true)}>
                  <IconUploadPhoto />
                  上傳照片
                </NavDropdown.Item>
                <NavDropdown.Item onClick={()=>props.modalsRef.current.renamePhotoModal.setShow(true)}>
                  <IconRenamePhoto />
                  更名照片
                </NavDropdown.Item>
                <NavDropdown.Item onClick={()=>props.modalsRef.current.deletePhotoModal.setShow(true)}>
                  <IconDeletePhoto />
                  刪除照片
                </NavDropdown.Item>
                <NavDropdown.Divider style={{ display: (user.role==='admin' || user.role==='maintainer') ? 'flex' : 'none' }} />
                <NavDropdown.Item 
                  /* disabled={user.role!=='admin'} */
                  style={{ display: (user.role==='admin' || user.role==='maintainer') ? 'flex' : 'none' }} 
                  onClick={()=>props.modalsRef.current.roleSettingModal.setShow(true)}>
                  <IconSetting />
                  設定
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="編輯" id="basic-nav-dropdown">
                <ItemUserBehaviorSwitcher />
                <NavDropdown.Divider />
                <ItemPredictionAutomaticImportSwitcher />
                <NavDropdown.Divider />
                <ItemSaveTargetRegions />
                <ItemDeleteActiveRegion />
                <ItemSelectALLRegions />
                <NavDropdown.Item disabled>
                  <IconLabelColor />
                  變更標記框顏色
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={()=>props.modalsRef.current.importTargetDataModal.setShow(true)}>
                  <IconImport />
                  匯入標記檔
                </NavDropdown.Item>
                <ItemExport />
                <NavDropdown.Divider style={{ display: user.role==='maintainer' ? 'flex' : 'none' }} />
                <ItemUpdatePhotoUrl />
                <NavDropdown.Item 
                  style={{ display: user.role==='maintainer' ? 'flex' : 'none' }} 
                  onClick={()=>props.modalsRef.current.fixPhotoDataModal.setShow(true)}>
                  <IconFixData />
                  修正標記資料
                </NavDropdown.Item>
                <NavDropdown.Item 
                  style={{ display: user.role==='maintainer' ? 'flex' : 'none' }} 
                  onClick={()=>props.modalsRef.current.exportDatasetModal.setShow(true)}>
                  <IconExportDataset />
                  匯出標記資料集
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="檢視" id="basic-nav-dropdown">
                <ItemPreviousPhoto />
                <ItemNextPhoto />
                {/* <NavDropdown.Item disabled><IconArrowLeft />上一張照片</NavDropdown.Item> */}
                {/* <NavDropdown.Item disabled><IconArrowRight />下一張照片</NavDropdown.Item> */}
                <ItemCloseActivePhoto />
                {/* <NavDropdown.Divider /> */}
                {/* <ItemZoomIn /> */}
                {/* <ItemZoomOut /> */}
                <ItemZoomOrigin />
              </NavDropdown>
              <NavDropdown title="執行" id="basic-nav-dropdown">
                <ItemRunPredict />
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={()=>props.modalsRef.current.predictSettingModal.setShow(true)}>
                  <IconSetting />
                  參數設定
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="說明" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={()=>{
                  /* window.open("https://doc.photo-id.kuroshio.org.tw/", "_blank") */
                  /* window.open("https://photo-id-app-docs.web.app/", "_blank") //測試用 */
                }}><IconDirection />使用說明</NavDropdown.Item>
                <NavDropdown.Item onClick={()=>props.modalsRef.current.aboutModal.setShow(true)}>
                  <IconAbout />
                  關於
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav className={styles.quickItems}>
              <QuickItemNewFolder onClick={()=>props.modalsRef.current.newFolderModal.setShow(true)} />
              <QuickItemUploadPhoto onClick={()=>props.modalsRef.current.newPhotosModal.setShow(true)} />
              {/* <QuickItemZoomIn /> */}
              {/* <QuickItemZoomOut /> */}
              <QuickItemZoomOrigin />
              <QuickItemRunPredict />
              <QuickItemSaveTargetRegions />
            </Nav>
          </div>
          <Nav className={styles.right}>
            <Location className="justify-content-end">
              <div style={{ color: 'white', marginRight: '12px', fontWeight: 600 }}>{displayName}</div>
              <Button variant="outline-light" size="sm" onClick={appSignOut}>登出</Button>
            </Location>
          </Nav>
        </Container>
      </Navbar>
    </>
  )
}
export default Header

const ItemUserBehaviorSwitcher = () => {
  const userBehaviorMode = useStore(state => state.userBehaviorMode)
  const setUserBehaviorMode = useStore(state => state.setUserBehaviorMode)

  const handleOnclick = e => {
    e.preventDefault()
    e.stopPropagation()
    setUserBehaviorMode(userBehaviorMode===CONS.BEHAVIOR_MODE_EDIT ? CONS.BEHAVIOR_MODE_VIEW : CONS.BEHAVIOR_MODE_EDIT)
    setTimeout(() => document.querySelector('.navbar').click(), 300)
  }

  return (
    <NavDropdown.Item onClick={handleOnclick}>
      {userBehaviorMode===CONS.BEHAVIOR_MODE_EDIT ? <IconEditMode /> : <IconViewMode />}
      <span style={{ opacity: userBehaviorMode!==CONS.BEHAVIOR_MODE_EDIT ? 0.4 : 1 }}>編輯模式</span>
      ｜
      <span style={{ opacity: userBehaviorMode!==CONS.BEHAVIOR_MODE_VIEW ? 0.4 : 1 }}>檢視模式</span>
    </NavDropdown.Item>
  )
}

const ItemPredictionAutomaticImportSwitcher = () => {
  const predictionAutomaticImport = useStore(state => state.predictionAutomaticImport)
  const setPredictionAutomaticImport = useStore(state => state.setPredictionAutomaticImport)

  const handleOnclick = e => {
    e.preventDefault()
    e.stopPropagation()
    setPredictionAutomaticImport(!predictionAutomaticImport)
    setTimeout(() => document.querySelector('.navbar').click(), 300)
  }

  return (
    <NavDropdown.Item onClick={handleOnclick}>
      {predictionAutomaticImport ? <IconAutoAddLabel /> : <IconManualAddLabel />}
      <span style={{ opacity: predictionAutomaticImport ? 1 : 0.4 }}>自動列入標記框</span>
      ｜
      <span style={{ opacity: !predictionAutomaticImport ? 1 : 0.4 }}>手動列入標記框</span>
    </NavDropdown.Item>
  )
}

const ItemSaveTargetRegions = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const cachedPhotoRegions = useStore(state => state.cachedPhotoRegions)
  const activeRegions = hasActiveIdPath() ? cachedPhotoRegions[getActiveIdPath()] : null
  const hasUnsavedRegions = activeRegions && (activeRegions.targetManual.length!==0 || activeRegions.targetPredict.length!==0 || JSON.stringify(activeRegions.targetStorage)!==activeRegions.originTargetRegionsString)
  const saveRegions = useStore(state => state.saveRegions)
  const disabled = !hasActiveIdPath() || !hasUnsavedRegions

  return (
    <NavDropdown.Item disabled={disabled} onClick={()=>saveRegions(getActiveIdPath())}>
      <IconSave />
      儲存標記框
    </NavDropdown.Item>
  )
}

const ItemDeleteActiveRegion = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const cachedPhotoRegions = useStore(state => state.cachedPhotoRegions)
  const activeRegions = hasActiveIdPath() ? cachedPhotoRegions[getActiveIdPath()] : null
  const disabled = !hasActiveIdPath() || (activeRegions && activeRegions.activeIds.length===0)

  const handleOnClick = e => {
    const closeBtns = document.querySelectorAll('div[data-region-status="true"]')
    if(closeBtns) closeBtns.forEach(btn => btn.click())
  }

  return (
    <NavDropdown.Item disabled={disabled} onClick={handleOnClick}>
      <IconDeleteLabel />
      刪除已選標記框
    </NavDropdown.Item>
  )
}

const ItemSelectALLRegions = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const cachedPhotoRegions = useStore(state => state.cachedPhotoRegions)
  const selectAllCachedPhotoActiveRegionIds = useStore(state => state.selectAllCachedPhotoActiveRegionIds)
  const activeRegions = hasActiveIdPath() ? cachedPhotoRegions[getActiveIdPath()] : null
  const regions = activeRegions ? Object.keys(activeRegions).filter(t => t === 'targetStorage' || t === 'targetManual' || t === 'targetPredict').map(type => activeRegions[type]).flat() : [] 
  
  const handleOnClick = e => {
    selectAllCachedPhotoActiveRegionIds(getActiveIdPath(), regions.map(r => r.id))
  }

  return (
    <NavDropdown.Item disabled={!hasActiveIdPath() || regions.length===0} onClick={handleOnClick}>
      <IconSelectAllRegions />
      全選標記框
    </NavDropdown.Item>
  )
}

const ItemPreviousPhoto = () => {
  const activeIds = useStore(state => state.activeIds)
  const selectPhoto = useStore(state => state.selectPhoto)
  const getPreviousShowingCachedData = useStore(state => state.getPreviousShowingCachedData)
  const prevIdPath = getPreviousShowingCachedData()
  
  const handleOnClick = e => {
    selectPhoto(prevIdPath.split('/')[0], prevIdPath.split('/')[1])
  }
  
  return (
    <NavDropdown.Item disabled={prevIdPath===null} onClick={handleOnClick}>
      <IconArrowLeft />
      上一張照片
    </NavDropdown.Item>
  )
}

const ItemNextPhoto = () => {
  const activeIds = useStore(state => state.activeIds)
  const selectPhoto = useStore(state => state.selectPhoto)
  const getNextShowingCachedData = useStore(state => state.getNextShowingCachedData)
  const nextIdPath = getNextShowingCachedData()

  const handleOnClick = e => {
    selectPhoto(nextIdPath.split('/')[0], nextIdPath.split('/')[1])
  }
  
  return (
    <NavDropdown.Item disabled={nextIdPath===null} onClick={handleOnClick}>
      <IconArrowRight />
      下一張照片
    </NavDropdown.Item>
  )
}

const ItemCloseActivePhoto = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)

  const handleOnClick = e => {
    const closeBtn = document.querySelector('div[data-photo-status="true"]')
    if(closeBtn) closeBtn.click()
  }
  
  return (
    <NavDropdown.Item disabled={!hasActiveIdPath()} onClick={handleOnClick}>
      <IconClose />
      關閉目前照片
    </NavDropdown.Item>
  )
}

const ItemExport = () => {
  const exportLabels = useStore(state => state.exportLabels)

  const handleOnClick = e => {
    exportLabels()
  }

  return (
    <NavDropdown.Item onClick={handleOnClick}>
      <IconExport />
      匯出標記統計資訊
    </NavDropdown.Item>
  )
}

const ItemUpdatePhotoUrl = () => {
  const user = useStore(state => state.user)
  const updatePhotoUrl = useStore(state => state.updatePhotoUrl)

  const handleOnClick = e => {
    updatePhotoUrl()
  }

  return (
    <NavDropdown.Item 
      style={{ display: user.role==='maintainer' ? 'flex' : 'none' }} 
      onClick={handleOnClick}>
      <IconRefresh />
      更新照片連結
    </NavDropdown.Item>
  )
}

const ItemRunPredict = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const predictPhoto = useStore(state => state.predictPhoto)

  const handlePredict = useCallback(e => {
    e.preventDefault()
    predictPhoto(getActiveIdPath())
  }, [])

  return (
    <NavDropdown.Item disabled={!hasActiveIdPath()} onClick={handlePredict}>
      <IconPredict />
      辨識 (F8)
    </NavDropdown.Item>
  )
}
/*
const ItemZoomOut = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const zoomOut = useStore(state => state.zoomOut)

  const handleOnClick = useCallback(e => {
    e.preventDefault()
    zoomOut(getActiveIdPath())
  }, [])

  return (
    <NavDropdown.Item disabled={!hasActiveIdPath()} onClick={handleOnClick}>
      <IconSizeToSmaller />
      縮小照片
    </NavDropdown.Item>
  )
}

const ItemZoomIn = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const zoomIn = useStore(state => state.zoomIn)

  const handleOnClick = useCallback(e => {
    e.preventDefault()
    zoomIn(getActiveIdPath())
  }, [])

  return (
    <NavDropdown.Item disabled={!hasActiveIdPath()} onClick={handleOnClick}>
      <IconSizeToBigger />
      放大照片
    </NavDropdown.Item>
  )
}
*/
const ItemZoomOrigin = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const zoomOrigin = useStore(state => state.zoomOrigin)

  const handleOnClick = useCallback(e => {
    e.preventDefault()
    zoomOrigin(getActiveIdPath())
  }, [])

  return (
    <NavDropdown.Item disabled={!hasActiveIdPath()} onClick={handleOnClick}>
      <IconSizeToOrigin />
      還原預設大小
    </NavDropdown.Item>
  )
}

const QuickItemNewFolder = props => 
  <OverlayTrigger
    placement="bottom"
    delay={{ show: 100, hide: 400 }}
    overlay={<Tooltip id="button-tooltip">新增目錄</Tooltip>}
  >
    <div className={styles.item} onClick={props.onClick}>
      <IconNewFolder/>
    </div>
  </OverlayTrigger>

const QuickItemUploadPhoto = props => 
  <OverlayTrigger
    placement="bottom"
    delay={{ show: 100, hide: 400 }}
    overlay={<Tooltip id="button-tooltip">上傳照片</Tooltip>}
  >
    <div className={styles.item} onClick={props.onClick}>
      <IconUploadPhoto />
    </div>
  </OverlayTrigger>

const QuickItemRunPredict = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const predictPhoto = useStore(state => state.predictPhoto)

  const handlePredict = useCallback(e => {
    e.preventDefault()
    predictPhoto(getActiveIdPath())
  }, [])

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 100, hide: 400 }}
      overlay={<Tooltip id="button-tooltip">辨識</Tooltip>}
    >
      <div 
        className={styles.item} 
        onClick={handlePredict} 
        style={{ opacity: hasActiveIdPath() ? 1 : 0.4, pointerEvents: hasActiveIdPath() ? 'all' : 'none' }}
      >
        <IconPredict />
      </div>
    </OverlayTrigger>
  )
}

const QuickItemSaveTargetRegions = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const cachedPhotoRegions = useStore(state => state.cachedPhotoRegions)
  const activeRegions = hasActiveIdPath() ? cachedPhotoRegions[getActiveIdPath()] : null
  const hasUnsavedRegions = activeRegions && (activeRegions.targetManual.length!==0 || activeRegions.targetPredict.length!==0 || JSON.stringify(activeRegions.targetStorage)!==activeRegions.originTargetRegionsString)
  const saveRegions = useStore(state => state.saveRegions)
  const disabled = !hasActiveIdPath() || !hasUnsavedRegions

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 100, hide: 400 }}
      overlay={<Tooltip id="button-tooltip">儲存標記框</Tooltip>}
    >
      <div 
        className={styles.item} 
        onClick={()=>saveRegions(getActiveIdPath())} 
        style={{ opacity: !disabled ? 1 : 0.4, pointerEvents: !disabled ? 'all' : 'none' }}
      >
        <IconSave />
      </div>
    </OverlayTrigger>
  )
}
/*
const QuickItemZoomOut = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const zoomOut = useStore(state => state.zoomOut)
  const disabled = !hasActiveIdPath()

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 100, hide: 400 }}
      overlay={<Tooltip id="button-tooltip">縮小照片</Tooltip>}
    >
      <div 
        className={styles.item} 
        onClick={()=>zoomOut(getActiveIdPath())} 
        style={{ opacity: !disabled ? 1 : 0.4, pointerEvents: !disabled ? 'all' : 'none' }}
      >
        <IconSizeToSmaller />
      </div>
    </OverlayTrigger>
  )
}

const QuickItemZoomIn = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const zoomIn = useStore(state => state.zoomIn)
  const disabled = !hasActiveIdPath()

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 100, hide: 400 }}
      overlay={<Tooltip id="button-tooltip">放大照片</Tooltip>}
    >
      <div 
        className={styles.item} 
        onClick={()=>zoomIn(getActiveIdPath())} 
        style={{ opacity: !disabled ? 1 : 0.4, pointerEvents: !disabled ? 'all' : 'none' }}
      >
        <IconSizeToBigger />
      </div>
    </OverlayTrigger>
  )
}
*/
const QuickItemZoomOrigin = () => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const zoomOrigin = useStore(state => state.zoomOrigin)
  const disabled = !hasActiveIdPath()

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 100, hide: 400 }}
      overlay={<Tooltip id="button-tooltip">還原預設大小</Tooltip>}
    >
      <div 
        className={styles.item} 
        onClick={()=>zoomOrigin(getActiveIdPath())} 
        style={{ opacity: !disabled ? 1 : 0.4, pointerEvents: !disabled ? 'all' : 'none' }}
      >
        <IconSizeToOrigin />
      </div>
    </OverlayTrigger>
  )
}
