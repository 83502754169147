import React, { forwardRef, useState, useImperativeHandle, useEffect } from "react"
import { deleteFolderApi, deletePhotoApi } from '@scripts/main.js'

import useStore from '@stores'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ListGroup from 'react-bootstrap/ListGroup'

export default forwardRef((props, ref) => {
  const photosByFolder = useStore(state => state.photosByFolder)
  // const deleteFolder = useStore(state => state.deleteFolder)
  const clearCachedPhoto = useStore(state => state.clearCachedPhoto)
  const addLog = useStore(state => state.addLog)
  const activeIds = useStore(state => state.activeIds)
  // const updateLists = useStore(state => state.updateLists)
  const [show, setShow] = useState(false)
  const [doubleCheckShow, setDoubleCheckShow] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [value, setValue] = useState('')
  const [messages, setMessages] = useState([])

  const folderIndex = photosByFolder.map(f => f.id).indexOf(value)

  useImperativeHandle(ref, () => ({
    setShow(bol) {
      setShow(bol)
    }
  }))

  useEffect(() => {
    if (show) {
      setValue(activeIds.folder || '')
    } else {
      reset()
      setMessages([])
    }
  }, [show])

  const reset = () => {
    setValue('')
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    addLog(`[${value}] 資料夾刪除中...`)

    if (folderIndex !== -1 && photosByFolder[folderIndex].photos.length !== 0) {
      await Promise.all(photosByFolder[folderIndex].photos.map(async photoId => {
        await deletePhotoApi(photosByFolder[folderIndex].id, photoId)

        clearCachedPhoto(photosByFolder[folderIndex].id, photoId)
      }))
    }

    const response = await deleteFolderApi(value)

    if(response.status==='success') {
      // updateLists()
      reset()
      addLog(`[${value}] 資料夾刪除完成`)
      // setShowSuccess(true)
      setShow(false)
    }
    if(response.status==='fail') {
      addLog(`[${value}] 資料夾刪除失敗！`)
    }
    
    setSubmitting(false)
    setDoubleCheckShow(false)
  }

  const handleOnChange = e => {
    const value = e.target.value
    setValue(value)
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        backdrop={!submitting ? true : 'static'}>
        <Modal.Header closeButton={!submitting}>
          <Modal.Title as="h5">刪除目錄</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={e => { e.preventDefault(); }}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>選取目錄</Form.Label>
              <Form.Select size="sm" value={value} onChange={handleOnChange} disabled={submitting}>
                <option value=''>未選取</option>
                {photosByFolder.map((f, i) => <option key={`delete-folder-item-${i}`} value={f.id}>{f.id}</option>)}
              </Form.Select>
            </Form.Group>
            
            {folderIndex !== -1 && photosByFolder[folderIndex].photos.length !== 0 ?
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>此目錄下的 {photosByFolder[folderIndex].photos.length} 張照片，將一併被刪除：</Form.Label>
                <ListGroup style={{ maxHeight: '300px', overflowY: 'auto' }}>
                  {photosByFolder[folderIndex].photos.map((photoId, i) =>
                    <ListGroup.Item>{i+1}. {photoId}</ListGroup.Item>
                  )}
                </ListGroup>
              </Form.Group> : ''}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={value === '' || submitting || messages.filter(m => m.type === 'error').length !== 0}
            variant="danger" size="sm" onClick={() => setDoubleCheckShow(true)}>
            刪除
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Modal
        show={doubleCheckShow} 
        onHide={()=>setDoubleCheckShow(false)}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        // size="sm"
        backdrop={!submitting ? true : 'static'}
        backdropClassName="z-index-higher"
        className="z-index-higher"
      >
        <Modal.Header closeButton={!submitting}>
          <Modal.Title as="h5">確定執行刪除？</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>您將刪除目錄「{value}」，並刪除此目錄下的 {folderIndex !== -1 && photosByFolder[folderIndex].photos.length !== 0 && photosByFolder[folderIndex].photos.length} 張照片。</p> 
        </Modal.Body>
        <Modal.Footer>
          <Button 
            disabled={submitting} 
            variant="normal" size="sm" onClick={()=>setDoubleCheckShow(false)}>
            取消
          </Button>
          <Button 
            disabled={submitting} 
            variant="danger" size="sm" onClick={handleSubmit}>
            刪除{submitting ? '中...' : ''}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
})