import React, { forwardRef, useState, useEffect, useImperativeHandle } from "react"
import { setConfigApi } from '@scripts/main.js'
import useStore from '@stores'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import validators from '../validators'

export default forwardRef((props, ref) => {
  const exportDataset = useStore(state => state.exportDataset)
  const labelThreshold = useStore(state => state.labelThreshold)
  const setLabelThreshold = useStore(state => state.setLabelThreshold)
  // const addLog = useStore(state => state.addLog)
  const [show, setShow] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [value, setValue] = useState(10)
  const [messages, setMessages] = useState([])
  const minValue = 10, maxValue = 100

  useImperativeHandle(ref, () => ({
    setShow(bol) {
      setShow(bol)
    }
  }));

  useEffect(() => {
    if (show) {
      setValue(labelThreshold)
    } else {
      reset()
      setMessages([])
    }
  }, [show])

  const reset = () => {
    setValue(labelThreshold)
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    exportDataset(value)

    // 如果標記門檻變更就寫入資料庫
    if (value!==labelThreshold) {
      const configRes = await setConfigApi('labelConfig', { labelThreshold: value })
      if (configRes.status==='success') {
        // console.log(`標記門檻更新完成`)
        setLabelThreshold(value)
      } else {
        console.error(`標記門檻更新失敗`, configRes.messages)
      }
    }
    
    reset()
    setShow(false)
    setSubmitting(false)
  }

  const handleOnChange = e => {
    const newValue = e.target.value ? parseInt(e.target.value) : ''
    setValue(newValue)

    let newMessages = []
    const rules = ['numberFormat']
    rules.map(r => {
      const response = validators[r](newValue, minValue, maxValue)
      if(!response.passed) newMessages.push(...response.messages)
    })
    setMessages(newMessages)
  }

  return (
    <Modal 
      show={show} 
      onHide={()=>setShow(false)}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      backdrop={!submitting ? true : 'static'}
      backdropClassName="z-index-higher"
      className="z-index-higher">
      <Modal.Header closeButton={!submitting}>
        <Modal.Title as="h5">匯出標記資料集</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={e => { e.preventDefault() }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>設定標記數量門檻 (介於10到100之間)</Form.Label>
            <Form.Control 
              type="number" min={minValue} max={maxValue}
              size="sm"
              placeholder="請輸入數值" 
              value={value}
              onChange={handleOnChange}
              disabled={submitting} />
            {messages.map((m, i) => 
              <Form.Text key={i} className="text-danger">{m.content}</Form.Text>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          disabled={value===null || submitting || messages.filter(m => m.type==='error').length!==0} 
          variant="secondary" size="sm" onClick={handleSubmit}>
          匯出{submitting ? '中...' : ''}
        </Button>
      </Modal.Footer>
    </Modal> 
  )
})