import React, { useCallback, useMemo, useRef } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import ListGroup from 'react-bootstrap/ListGroup'
import ExpandedText from './ExpandedText.jsx';
import IconFolder from '@assets/icons/folder'
import IconFolderOpen from '@assets/icons/folder-open'
import FileTypeDolphin from '@assets/icons/file-type-dolphin.js'
import FileTypeLabelDolphin from '@assets/icons/file-type-label-dolphin.js'

import useStore from '@stores'

const FileList = props => {
  const { scrollRef, active } = props
  const ref = useRef(null)

  return (
    <div ref={ref} style={{ height: '100%' }}>
      <ExpandedText wrapperRef={ref} scrollRef={scrollRef} active={active} />
      <FolderLists />
    </div>
  )
}

const FolderLists = () => {
  const labelList = useStore(state => state.labelList)
  const labelThreshold = useStore(state => state.labelThreshold)

  return (
    labelList.map((lo, i) => {
      const count = lo.folders.map(f => f.photos.map(p => p.boxs.length).reduce((a, b) => a+b)).reduce((a, b) => a+b)

      return (
        <Accordion key={`label-${lo.name}`} id={`label-${lo.name}`} flush>
          <Accordion.Item eventKey={`label-${lo.name}`}>
            <Accordion.Header className={`${count>=labelThreshold ? 'highlight' : ''}`}>
              <FileTypeLabelDolphin className="svg-inline--fa" />
              {/* <span className="text expandable-text">{lo.name!=='' ? `KU_${lo.name}` : '(empty)'}</span> */}
              <span className="text expandable-text">{lo.name!=='' ? `${lo.name}` : '(empty)'}</span>
              <span style={{ marginLeft: 'auto' }}>{count}</span>
            </Accordion.Header>
            <Accordion.Collapse eventKey={`label-${lo.name}`}>
              <ListGroup variant="flush">
                {lo.folders.map((folder, j) =>
                  <FolderList
                    key={`label-list-${j}-${folder.id}`} 
                    folder={folder}
                    labelName={lo.name}
                  />
                )}
              </ListGroup>
            </Accordion.Collapse>
          </Accordion.Item>
        </Accordion>
      )
    })
  )
}

const FolderList = props => {
  // const updateActiveFolderId = useStore(state => state.updateActiveFolderId)
  const { folder, labelName } = props

  // const handleFolderOnClick = useCallback(e => {
  //   e.preventDefault()
    
  //   if(e.target.classList.contains('collapsed') || e.target.parentElement.classList.contains('collapsed')) {
  //     updateActiveFolderId(folder.id)
  //   }
  // }, [folder.id])

  const renderFolderItem = () =>
    <Accordion.Header 
      className="intend-1" 
      // onClick={handleFolderOnClick}
    >
      <IconFolder className="svg-inline--fa fa-folder" />
      <IconFolderOpen className="svg-inline--fa fa-folder-open" />
      <span className="text expandable-text">{folder.id}</span>
    </Accordion.Header>

  return (
    <Accordion id={`folder-${folder.id}`} flush>
      <Accordion.Item eventKey={`label-${labelName}-folder-${folder.id}`}>
        {renderFolderItem()}
        <Accordion.Collapse eventKey={`label-${labelName}-folder-${folder.id}`}>
          <FileItems folder={folder} labelName={labelName} />
        </Accordion.Collapse>
      </Accordion.Item>
    </Accordion>
  )
}

const FileItems = props => {
  const { folder, labelName } = props
  const folderId = folder.id

  return (
    <ListGroup variant="flush">
      {folder.photos.map((photo, j) => (
        <FileItem 
          folderId={folderId}
          photoId={photo.id}
          key={`label-${labelName}-${j}-file-list-${folderId}-${photo.id}`}
        />
      ))}
    </ListGroup>
  )
}

const FileItem = props => {
  const selectPhoto = useStore(state => state.selectPhoto)
  const activeIds = useStore(state => state.activeIds)
  const { folderId, photoId } = props;
  const active = activeIds.folder===folderId && activeIds.photo===photoId

  const handleSelect = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
    selectPhoto(folderId, photoId)
  }, [])

  return useMemo(() => (
    <ListGroup.Item
      onClick={handleSelect} 
      className={`intend-2 ${active ? 'active' : ''}`}
    >
      <FileTypeDolphin className="svg-inline--fa" />
      <span className="text expandable-text">{photoId}</span>
    </ListGroup.Item>
  ), [active])
}

export default FileList