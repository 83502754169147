import React, { forwardRef, useState, useImperativeHandle, useEffect } from "react"
// import { getAllPhotosDataApi, getPhotoSizeApi, updatePhotoDataApi } from '@scripts/main.js'
// import { clampUnit } from '@utils/index'
import useStore from '@stores'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export default forwardRef((props, ref) => {
  const photosByFolder = useStore(state => state.photosByFolder)
  const fixPhotoData = useStore(state => state.fixPhotoData)
  // const addLog = useStore(state => state.addLog)
  const activeIds = useStore(state => state.activeIds)
  // const updateLists = useStore(state => state.updateLists)
  const [show, setShow] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [value, setValue] = useState('')
  // const [messages, setMessages] = useState([])
  const folderIndex = photosByFolder.map(f => f.id).indexOf(value)

  useImperativeHandle(ref, () => ({
    setShow(bol) {
      setShow(bol)
    }
  }))

  useEffect(() => {
    if (show) {
      setValue(activeIds.folder || '')
    } else {
      reset()
      // setMessages([])
    }
  }, [show])

  const reset = () => {
    setValue('')
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    await fixPhotoData(value)
    reset()
    setShow(false)
    setSubmitting(false)
  }

  const handleOnChange = e => {
    const value = e.target.value
    setValue(value)
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        backdrop={!submitting ? true : 'static'}>
        <Modal.Header closeButton={!submitting}>
          <Modal.Title as="h5">修正標記資料</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={e => { e.preventDefault(); }}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>選取目錄</Form.Label>
              <Form.Select size="sm" value={value} onChange={handleOnChange} disabled={submitting}>
                <option value=''>未選取</option>
                {photosByFolder.map((f, i) => <option key={`fix-item-${i}`} value={f.id}>{f.id}</option>)}
              </Form.Select>
              {folderIndex !== -1 && photosByFolder[folderIndex].photos.length !== 0 ?
                <Form.Label>此目錄有 {photosByFolder[folderIndex].photos.length} 張照片</Form.Label> : '此目錄無照片'}
            </Form.Group>
            {/*folderIndex !== -1 && photosByFolder[folderIndex].photos.length !== 0 ?
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>此目錄有 {photosByFolder[folderIndex].photos.length} 張照片</Form.Label>
              </Form.Group> : '此目錄無照片'*/}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            disabled={value==='' || submitting || 
              folderIndex === -1 || photosByFolder[folderIndex].photos.length === 0} 
            variant="secondary" size="sm" onClick={handleSubmit}>
            修正{submitting ? '中...' : ''}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
})