import React, { forwardRef, useState, useEffect, useImperativeHandle } from "react"
import { addFolderApi } from '@scripts/main.js'

import useStore from '@stores'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
// import Alert from './Alert'

import validators from '../validators'

export default forwardRef((props, ref) => {
  const { modalsRef } = props
  const photosByFolder = useStore(state => state.photosByFolder)
  // const updateLists = useStore(state => state.updateLists)
  const addLog = useStore(state => state.addLog)
  // const updateActiveFolderId = useStore(state => state.updateActiveFolderId)
  const [show, setShow] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [value, setValue] = useState('')
  const [messages, setMessages] = useState([])

  useImperativeHandle(ref, () => ({
    setShow(bol) {
      setShow(bol)
    }
  }));

  useEffect(() => {
    if (show) {
    } else {
      reset()
      setMessages([])
    }
  }, [show])

  const reset = () => {
    setValue('')
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    addLog(`[${value}] 資料夾新增中...`)
    const folderExists = photosByFolder.map(f => f.id).includes(value)
    if (!folderExists) {
      const response = await addFolderApi(value)
      if(response.status==='success') {
        if(modalsRef.current.newPhotosModal.getShowState()) {
          modalsRef.current.newPhotosModal.addNewFolder(value)
        }
        // updateLists()
        reset()
        // setShowSuccess(true)
        setShow(false)
        addLog(`[${value}] 資料夾新增完成`)
      }
      if(response.status==='fail') {
        setMessages(response.messages)
        addLog(`[${value}] 資料夾新增失敗！`)
      }
    } else {
      setMessages([{ type: 'error', content: `目錄名稱已存在，請變更名稱` }])
      addLog(`[${value}] 資料夾新增失敗！`)
    }
    setSubmitting(false)
  }

  const handleOnChange = e => {
    const value = e.target.value.trim()
    setValue(value)

    let newMessages = []

    const rules = ['required', 'fileNameFormat']
    rules.map(r => {
      const response = validators[r](value)
      if(!response.passed) newMessages.push(...response.messages)
    })

    setMessages(newMessages)
  }

  return (
    <Modal 
      show={show} 
      onHide={()=>setShow(false)}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      backdrop={!submitting ? true : 'static'}
      backdropClassName="z-index-higher"
      className="z-index-higher"
    >
      <Modal.Header closeButton={!submitting}>
        <Modal.Title as="h5">新增目錄</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={e => { e.preventDefault() }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>目錄名稱</Form.Label>
            <Form.Control 
              type="text" 
              size="sm"
              placeholder="請輸入目錄名稱" 
              value={value}
              onChange={handleOnChange} 
              disabled={submitting} />
            {messages.map((m, i) => 
              <Form.Text key={i} className="text-danger">{m.content}</Form.Text>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          disabled={value==='' || submitting || messages.filter(m => m.type==='error').length!==0} 
          variant="secondary" size="sm" onClick={handleSubmit}>
          新增{submitting ? '中...' : ''}
        </Button>
      </Modal.Footer>
    </Modal> 
  )
})