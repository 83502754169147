import React, { useEffect, forwardRef } from 'react'
import useStore from '@stores'
import styles from './main.module.scss'

export default forwardRef((props, ref) => {
  const logs = useStore(state => state.logs)

  useEffect(() => {
    if(logs) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  }, [logs])

  return (
    <div 
      ref={ref} 
      className={styles.statusWrapper}
    >
      <div className={styles.logs}>
        {logs.map((d, i) => 
          <p key={`log-${i}`} dangerouslySetInnerHTML={{ __html: d }} />
        )}
      </div>
    </div>
  )
})