import ku000L from './000_L.jpg'
import ku000R from './000_R.jpg'
import ku007L from './007_L.jpg'
import ku014L from './014_L.jpg'
import ku014R from './014_R.jpg'
import ku015L from './015_L.jpg'
import ku015R from './015_R.jpg'
import ku016L from './016_L.jpg'
import ku016R from './016_R.jpg'
import ku017L from './017_L.jpg'
import ku017R from './017_R.jpg'
import ku018L from './018_L.jpg'
import ku018R from './018_R.jpg'
import ku019L from './019_L.jpg'
import ku020L from './020_L.jpg'
import ku020R from './020_R.jpg'
import ku021L from './021_L.jpg'
import ku021R from './021_R.jpg'
import ku022L from './022_L.jpg'
import ku022R from './022_R.jpg'
import ku034L from './034_L.jpg'
import ku034R from './034_R.jpg'
import ku040R from './040_R.jpg'
import ku054L from './054_L.jpg'
import ku054R from './054_R.jpg'
import ku055L from './055_L.jpg'
import ku055R from './055_R.jpg'
import ku056L from './056_L.jpg'
import ku056R from './056_R.jpg'
import ku057L from './057_L.jpg'
import ku057R from './057_R.jpg'
import ku059L from './059_L.jpg'
import ku059R from './059_R.jpg'
import ku060L from './060_L.jpg'
import ku061L from './061_L.jpg'
import ku061R from './061_R.jpg'
import ku062L from './062_L.jpg'
import ku062R from './062_R.jpg'
import ku063L from './063_L.jpg'
import ku063R from './063_R.jpg'
import ku064L from './064_L.jpg'
import ku064R from './064_R.jpg'
import ku070L from './070_L.jpg'
import ku070R from './070_R.jpg'
import ku072L from './072_L.jpg'
import ku072R from './072_R.jpg'
import ku073L from './073_L.jpg'
import ku073R from './073_R.jpg'
import ku074L from './074_L.jpg'
import ku074R from './074_R.jpg'
import ku075L from './075_L.jpg'
import ku075R from './075_R.jpg'
import ku076L from './076_L.jpg'
import ku076R from './076_R.jpg'
import ku077L from './077_L.jpg'
import ku077R from './077_R.jpg'
import ku078L from './078_L.jpg'
import ku079L from './079_L.jpg'
import ku082R from './082_R.jpg'
import ku083L from './083_L.jpg'
import ku083R from './083_R.jpg'
import ku084R from './084_R.jpg'
import ku085R from './085_R.jpg'
import ku094L from './094_L.jpg'
import ku094R from './094_R.jpg'
import ku099L from './099_L.jpg'
import ku105L from './105_L.jpg'
import ku105R from './105_R.jpg'
import ku108L from './108_L.jpg'
import ku108R from './108_R.jpg'
import ku114L from './114_L.jpg'
import ku114R from './114_R.jpg'
import ku118L from './118_L.jpg'
import ku118R from './118_R.jpg'
import ku120L from './120_L.jpg'
import ku120R from './120_R.jpg'
import ku121L from './121_L.jpg'
import ku121R from './121_R.jpg'
import ku124L from './124_L.jpg'
import ku124R from './124_R.jpg'
import ku125L from './125_L.jpg'
import ku125R from './125_R.jpg'
import ku126R from './126_R.jpg'
import ku186L from './186_L.jpg'
import ku214L from './214_L.jpg'
import ku225L from './225_L.jpg'
import ku272R from './272_R.jpg'
import ku301L from './301_L.jpg'
import ku301R from './301_R.jpg'
import ku302L from './302_L.jpg'
import ku302R from './302_R.jpg'
import ku303L from './303_L.jpg'
import ku303R from './303_R.jpg'
import ku304L from './304_L.jpg'
import ku304R from './304_R.jpg'
import ku305L from './305_L.jpg'
import ku305R from './305_R.jpg'
import ku316L from './316_L.jpg'
import ku316R from './316_R.jpg'
import ku327L from './327_L.jpg'
import ku329L from './329_L.jpg'
import ku329R from './329_R.jpg'
import ku331L from './331_L.jpg'
import ku331R from './331_R.jpg'
import ku332L from './332_L.jpg'
import ku332R from './332_R.jpg'
import ku359L from './359_L.jpg'
import ku396L from './396_L.jpg'
import ku397R from './397_R.jpg'
import ku398L from './398_L.jpg'
import ku399L from './399_L.jpg'
import ku399R from './399_R.jpg'
import ku400L from './400_L.jpg'
import ku400R from './400_R.jpg'
import tempL from './L.jpg'
import tempR from './R.jpg'

const labelImgs = {
  '000': { left: ku000L, right: ku000R },
  '007': { left: ku007L, right: null },
  '014': { left: ku014L, right: ku014R },
  '015': { left: ku015L, right: ku015R },
  '016': { left: ku016L, right: ku016R },
  '017': { left: ku017L, right: ku017R },
  '018': { left: ku018L, right: ku018R },
  '019': { left: ku019L, right: null },
  '020': { left: ku020L, right: ku020R },
  '021': { left: ku021L, right: ku021R },
  '022': { left: ku022L, right: ku022R },
  '034': { left: ku034L, right: ku034R },
  '040': { left: null, right: ku040R },
  '054': { left: ku054L, right: ku054R },
  '055': { left: ku055L, right: ku055R },
  '056': { left: ku056L, right: ku056R },
  '057': { left: ku057L, right: ku057R },
  '059': { left: ku059L, right: ku059R },
  '060': { left: ku060L, right: null },
  '061': { left: ku061L, right: ku061R },
  '062': { left: ku062L, right: ku062R },
  '063': { left: ku063L, right: ku063R },
  '064': { left: ku064L, right: ku064R },
  '070': { left: ku070L, right: ku070R },
  '072': { left: ku072L, right: ku072R },
  '073': { left: ku073L, right: ku073R },
  '074': { left: ku074L, right: ku074R },
  '075': { left: ku075L, right: ku075R },
  '076': { left: ku076L, right: ku076R },
  '077': { left: ku077L, right: ku077R },
  '078': { left: ku078L, right: null },
  '079': { left: ku079L, right: null },
  '082': { left: null, right: ku082R },
  '083': { left: ku083L, right: ku083R },
  '084': { left: null, right: ku084R },
  '085': { left: null, right: ku085R },
  '094': { left: ku094L, right: ku094R },
  '099': { left: ku099L, right: null },
  '105': { left: ku105L, right: ku105R },
  '108': { left: ku108L, right: ku108R },
  '114': { left: ku114L, right: ku114R },
  '118': { left: ku118L, right: ku118R },
  '120': { left: ku120L, right: ku120R },
  '121': { left: ku121L, right: ku121R },
  '124': { left: ku124L, right: ku124R },
  '125': { left: ku125L, right: ku125R },
  '126': { left: null, right: ku126R },
  '186': { left: ku186L, right: null },
  '214': { left: ku214L, right: null },
  '225': { left: ku225L, right: null },
  '272': { left: null, right: ku272R },
  '301': { left: ku301L, right: ku301R },
  '302': { left: ku302L, right: ku302R },
  '303': { left: ku303L, right: ku303R },
  '304': { left: ku304L, right: ku304R },
  '305': { left: ku305L, right: ku305R },
  '316': { left: ku316L, right: ku316R },
  '327': { left: ku327L, right: null },
  '329': { left: ku329L, right: ku329R },
  '331': { left: ku331L, right: ku331R },
  '332': { left: ku332L, right: ku332R },
  '359': { left: ku359L, right: null },
  '396': { left: ku396L, right: null },
  '397': { left: null, right: ku397R },
  '398': { left: ku398L, right: null },
  '399': { left: ku399L, right: ku399R },
  '400': { left: ku400L, right: ku400R },
  'temp': { left: tempL, right: tempR },
}

export default labelImgs