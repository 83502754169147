import React from 'react'
import Header from './Header'
import Content from '../../components/Content'
import firebaseApp from '../../app/firebaseApp'
import firebase from 'firebase'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import bg from '@assets/images/sign-in-bg.jpg'

const auth = firebase.auth(firebaseApp)
const EmailAuthProvider = firebase.auth.EmailAuthProvider

const uiConfig = {
  signInSuccessUrl: '/',
  signInFlow: 'popup',
  signInOptions: [{
      provider: EmailAuthProvider.PROVIDER_ID,
      disableSignUp: {
        status: true
      }
    }
  ],
};

const signIn = props => {
  return (
    <>
      <Header />

      <Content
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </div>
      </Content>
    </>
  )
}

export default signIn