import React from 'react'

const Content = props => {
  const { style } = props

  return (
    <div style={{ 
      position: 'relative',
      height: 'calc(100vh - 44px)',
      backgroundColor: '#f5f6f7',

      ...style,
     }}>{props.children}</div>
  )
}

export default Content