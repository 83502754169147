import React, { forwardRef, useState, useImperativeHandle, useEffect } from "react"
import { updatePhotoRegionsApi } from '@scripts/main.js'

import useStore from '@stores'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'

export default forwardRef((props, ref) => {
  const photosByFolder = useStore(state => state.photosByFolder)
  const addLog = useStore(state => state.addLog)
  const activeIds = useStore(state => state.activeIds)
  const cachedPhotoRegions = useStore(state => state.cachedPhotoRegions)
  const updateCachedPhotoRegionsTargetStorage = useStore(state => state.updateCachedPhotoRegionsTargetStorage)
  // const updateLists = useStore(state => state.updateLists)
  const [show, setShow] = useState(false)
  const [doubleCheckShow, setDoubleCheckShow] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [value, setValue] = useState('')
  const [fileDataLoading, setFileDataLoading] = useState(false)
  const [fileData, setFileData] = useState(null)
  const [rawData, setRawData] = useState(null)
  // const [messages, setMessages] = useState([])

  const countOfPhoto = fileData && Object.keys(fileData).length>0 && Object.keys(fileData).length
  const countOfTarget = fileData && Object.keys(fileData).length>0 && Object.keys(fileData).map(key => fileData[key].length).reduce((a, b) => a+b)

  const folderIndex = photosByFolder.map(f => f.id).indexOf(value)

  useImperativeHandle(ref, () => ({
    setShow(bol) {
      setShow(bol)
    }
  }))

  useEffect(() => {
    if (show) {
      setValue(activeIds.folder || '')
    } else {
      reset()
      // setMessages([])
    }
  }, [show])

  const reset = () => {
    setValue('')
    setFileData(null)
    setRawData(null)
    setFileDataLoading(false)
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    addLog(`[${value}] 標記檔匯入中...`)
  
    let failed = false
    await Promise.all(Object.keys(fileData).map(async key => {
      const idPath = `${value}/${key}`
      // if has cached regions data, update it!
      if(cachedPhotoRegions[idPath]!==undefined) {
        updateCachedPhotoRegionsTargetStorage(idPath, fileData[key])
      }

      const response = await updatePhotoRegionsApi(value, key, fileData[key])
      if(response.status==='success') {
        console.log(`${key} 匯入完成`, fileData[key])
      }
      if(response.status==='fail') {
        console.error(`${key} 匯入失敗`, response.messages)
        failed = true
      }
    }))

    if (failed) {
      addLog(`[${value}] 標記檔匯入失敗！請查看主控台訊息`)
    } else {
      addLog(`[${value}] 標記檔匯入完成`)
      // updateLists()
    }
    reset()
    // setShowSuccess(true)
    setShow(false)
    setSubmitting(false)
    setDoubleCheckShow(false)
  }

  const handleFolderOnChange = e => {
    const value = e.target.value
    setValue(value)
    if(rawData) setFileData(formatData(value, rawData))
  }

  const handleFileOnChange = e => {    
    let fr = new FileReader();
  
    if(e.target.files.length>0) {
      setFileDataLoading(true)

      fr.onload = e => { 
        let data = JSON.parse(e.target.result)

        setRawData(data)
        setFileData(formatData(value, data))
        setFileDataLoading(false)
      }
      fr.readAsText(e.target.files[0])
    } 
  }

  const formatData = (folderId, data) => {
    let formattedData = {}
    const folderIndex = photosByFolder.map(f => f.id).indexOf(folderId)
    Object.keys(data).map(key => {
      const photoIndex = photosByFolder[folderIndex].photos.indexOf(data[key].filename)
      if(photoIndex!==-1) {
        const photoId = photosByFolder[folderIndex].photos[photoIndex]
        if(Object.keys(data[key].regions).length>0) {
          formattedData[photoId] = Object.keys(data[key].regions)
            .filter(rKey => data[key].regions[rKey].region_attributes.KU_ID !== undefined)
            .map(rKey => 
              ({ 
                box: {
                  height: data[key].regions[rKey].shape_attributes.height,
                  left: data[key].regions[rKey].shape_attributes.x,
                  top: data[key].regions[rKey].shape_attributes.y,
                  width: data[key].regions[rKey].shape_attributes.width
                  // xmin: size ? (data[key].regions[rKey].shape_attributes.x / size.width) : null,
                  // ymin: size ? (data[key].regions[rKey].shape_attributes.y / size.height) : null,
                  // xmax: size ? ((data[key].regions[rKey].shape_attributes.x + data[key].regions[rKey].shape_attributes.width) / size.width) : null,
                  // ymax: size ? ((data[key].regions[rKey].shape_attributes.y + data[key].regions[rKey].shape_attributes.height) / size.height) : null,
                },
                label: data[key].regions[rKey].region_attributes.KU_ID,
              }))
        } else {
          formattedData[photoId] = []
        }
      }
    })
    return formattedData
  }

  return (
    <>
      <Modal 
        show={show} 
        onHide={()=>setShow(false)}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        backdrop={!submitting ? true : 'static'}>
        <Modal.Header closeButton={!submitting}>
          <Modal.Title as="h5">匯入標記檔</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={e => { e.preventDefault(); }}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>匯入對象（目錄）</Form.Label>
              <Form.Select size="sm" value={value} onChange={handleFolderOnChange} disabled={submitting}>
                <option value=''>未選取</option>
                {photosByFolder.map((f, i) => <option key={`import-region-folder-item-${i}`}  value={f.id}>{f.id}</option>)}
              </Form.Select>
            </Form.Group>
            
            {folderIndex !== -1 ? (
              photosByFolder[folderIndex].photos.length !== 0 ?
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>上傳標記檔</Form.Label>
                  <Form.Control type="file" size="sm" accept=".json" onChange={handleFileOnChange} disabled={submitting} />
                </Form.Group> : '此目錄，無檔案可匯入標記'
            ) : ''}
            
            {fileDataLoading ? '標記檔載入中' : (
              fileData ? 
                <>
                  <Form.Label>
                    即將匯入的標記
                    {Object.keys(fileData).length>0 ? <span>（共 {countOfPhoto} 張照片、{countOfTarget} 個標記）</span> : ''}
                    ：
                  </Form.Label>
                  <Card style={{ fontSize: '14px', maxHeight: '300px', overflowY: 'auto' }}>
                    {Object.keys(fileData).length===0 ? <div style={{ padding: '0.5rem' }}>無匹配的標記</div> :
                      <pre style={{ padding: '0.5rem', margin: 0 }}>
                        {Object.keys(fileData).map((key, i) => 
                          <div key={`import-photo-data-${i}`} >
                            <div>{`${key}: {`}</div>
                            <div>{`  regions: [`}</div>
                            {fileData[key].map((r, j) =>
                              <div key={`import-photo-data-${i}-${j}`}>
                                <div>{`    {`}</div>
                                <div>{`      box: { `}</div>
                                <div>{`        width: ${r.box.width},`}</div>
                                <div>{`        height: ${r.box.height},`}</div>
                                <div>{`        left: ${r.box.left},`}</div>
                                <div>{`        top: ${r.box.top}`}</div>
                                <div>{`      },`}</div>
                                <div>{`      label: ${r.label}`}</div>
                                <div>{`    },`}</div>
                              </div>
                            )}
                            <div>{`  ]`}</div>
                            <div>{`},`}</div>
                          </div>)}
                      </pre>}
                  </Card>
                </> : ''
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            disabled={value==='' || fileData===null || Object.keys(fileData).length===0 || submitting} 
            variant="secondary" size="sm" onClick={() => setDoubleCheckShow(true)}>
            匯入
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={doubleCheckShow} 
        onHide={()=>setDoubleCheckShow(false)}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        // size="sm"
        backdrop={!submitting ? true : 'static'}
        backdropClassName="z-index-higher"
        className="z-index-higher"
      >
        <Modal.Header closeButton={!submitting}>
          <Modal.Title as="h5">確定執行匯入？</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>您將匯入 {countOfPhoto} 張照片的共 {countOfTarget} 個標記</p>
          <p>※ 提醒，照片的新的標記資料將會「覆蓋」掉舊的標記資料。</p>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            disabled={submitting} 
            variant="normal" size="sm" onClick={()=>setDoubleCheckShow(false)}>
            取消
          </Button>
          <Button 
            disabled={submitting} 
            variant="secondary" size="sm" onClick={handleSubmit}>
            匯入{submitting ? '中...' : ''}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
})