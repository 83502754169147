import React from 'react'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

const Header = props => {
  return (
    <Navbar bg="secondary" variant="dark" style={{ zIndex: 1, boxShadow: '0 .25rem .25rem rgba(0, 0, 0, .15)' }}>
      <Container fluid>
        <Nav>
          <Navbar.Brand>
            Photo-ID
          </Navbar.Brand>
        </Nav>
        <Nav>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default Header