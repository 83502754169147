import React, { useRef, forwardRef, useEffect, useState } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import Tabs from './Tabs'
import Controls from './Controls'
import LogBox from './LogBox'
import Canvas from './Canvas'
import Resizer from '@components/resizer'
import WebFont from 'webfontloader'
import useSize from '../../../hooks/useSize'
import useStore from '@stores'
import styles from './main.module.scss'
// import { getPhotoDataApi } from '@scripts/main.js'
import { drawRegion } from './drawers'
import CONS from '@stores/constant'
import { clamp } from '@utils/index'

export default forwardRef((props, ref) => {
  const cachedPhotoData = useStore(state => state.cachedPhotoData)
  const loadingPhoto = useStore(state => state.loadingPhoto)
  const setLoadingPhoto = useStore(state => state.setLoadingPhoto)
  const refTop = useRef(null)
  const refBottom = useRef(null)
  const refTopSize = useSize(refTop)
  const [editorSize, setEditorSize] = useState({ width: 0, prevWidth: 0 })

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleResize = () => {
    if(refTop.current)
      refTop.current.style.height = (ref.current.offsetHeight - 34 - refBottom.current.offsetHeight) + 'px'
  }

  useEffect(() => {
    if (refTopSize.width) {
      setEditorSize(prev => ({ width: refTopSize.width, prevWidth: prev.width }))
    }
  }, [refTopSize.width])

  return (
    <div ref={ref} className={styles.centerWrapper}>
      <Tabs />

      <Controls />

      <div ref={refTop} className={styles.innerWrapper}>
        {Object.keys(cachedPhotoData).map((idPath, i) =>
          <TabContent
            key={`photo-wrapper-${idPath}`}
            folderId={cachedPhotoData[idPath].folderId}
            photoId={cachedPhotoData[idPath].photoId}
            photoUrl={cachedPhotoData[idPath].photoUrl}
            show={cachedPhotoData[idPath].show}
            resetZoom={cachedPhotoData[idPath].resetZoom}
            loading={loadingPhoto[idPath]}
            setLoading={(bol) => setLoadingPhoto(idPath, bol)} 
            editorSize={editorSize}
          />)}
      </div>
      
      <PredictPhoto scrollRef={refTop} />
      
      <InfoBox bottomRef={refBottom} />

      <Resizer 
        type="horizontal" 
        refBefore={refTop}  
        refAfter={refBottom}
        beforeMin={200}
        afterMin={60}
      />

      <LogBox ref={refBottom} />
    </div>
  )
})

const PredictPhoto = props => {
  const activeIds = useStore(state => state.activeIds)
  const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const cachedPhotoData = useStore(state => state.cachedPhotoData)
  const activePhotoData = hasActiveIdPath() ? cachedPhotoData[getActiveIdPath()] : null
  
  // const right = (activePhotoData && (props.scrollRef.current.clientHeight < props.scrollRef.current.scrollHeight)) ? '24px' : '8px'

  if (!activePhotoData || !activePhotoData.predictPhotoUrl) return <></>

  return (
    <img 
      className={styles.predictPhoto} 
      // style={{ right }}
      src={activePhotoData.predictPhotoUrl} 
      alt="" 
    />
  )
}

const InfoBox = props => {
  const { bottomRef } = props
  // const hasActiveIdPath = useStore(state => state.hasActiveIdPath)
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const cachedPhotoData = useStore(state => state.cachedPhotoData)
  // const photoSize = hasActiveIdPath() ? cachedPhotoData[getActiveIdPath()].currentSize : null
  const photoSize = cachedPhotoData[getActiveIdPath()] ? cachedPhotoData[getActiveIdPath()].currentSize : null
  const bottom = bottomRef.current ? bottomRef.current.offsetHeight + 'px' : 0

  // if (!hasActiveIdPath() || !photoSize) return <></>
  if (!photoSize) return <></>
  return (
    <div className={styles.infobox} style={{bottom}}>
      <p>
        <span>{photoSize.width} x {photoSize.height}</span>
      </p>
    </div>
  )
}

const TabContent = (props) => {
  const { loading, photoId, folderId, photoUrl, show, resetZoom, setLoading, editorSize } = props
  const addLog = useStore(state => state.addLog)
  const activeIds = useStore(state => state.activeIds)
  const initCachedPhoto = useStore(state => state.initCachedPhoto)
  const idPath = `${folderId}/${photoId}`
  const checkPhotoSize = useStore(state => state.checkPhotoSize)
  const currentCachedPhotoRegions = useStore(state => state.cachedPhotoRegions[idPath])
  const getActiveIdPath = useStore(state => state.getActiveIdPath)
  const userBehaviorMode = useStore(state => state.userBehaviorMode)
  const collData = useStore(state => state.collData)
  // const cachedPhotoData = useStore(state => state.cachedPhotoData)
  const updateCachedPhotoData = useStore(state => state.updateCachedPhotoData)
  const clearCachedPhoto = useStore(state => state.clearCachedPhoto)

  const photoWrapperRef = useRef(null)
  const photoRef = useRef(null)
  const photoWrapper = useSize(photoWrapperRef)
  const [canvasConfig, setCanvasConfig] = useState({ width: 0, height: 0, scale: 1, naturalWidth: 0, naturalHeight: 0 })
  const [loaded, setLoaded] = useState(false)
  const [failed, setFailed] = useState(false)
  const [panning, setPanning] = useState(false)
  const [translate, setTranslate] = useState({ startX: 0, startY: 0, x: 0, y: 0, scale: 1.0 })
  // const translate = loaded ? cachedPhotoData[idPath].translate : { x: 0, y: 0, scale: 1.0 }
  // const [pos, setPos] = useState({ x: 0, y: 0 })

  const predictRegions = currentCachedPhotoRegions ? currentCachedPhotoRegions.predict : []
  const targetRegions = currentCachedPhotoRegions ? [
    ...currentCachedPhotoRegions.targetStorage,
    ...currentCachedPhotoRegions.targetPredict,
    ...currentCachedPhotoRegions.targetManual,
  ] : []
  const activeRegionIds = currentCachedPhotoRegions ? currentCachedPhotoRegions.activeIds : null
  const hovering = currentCachedPhotoRegions && currentCachedPhotoRegions.hovering ? currentCachedPhotoRegions.hovering : null
  const activeRegions = activeRegionIds ? targetRegions.filter(r => activeRegionIds.indexOf(r.id) !== -1) : null

  useEffect(() => {
    if (photoWrapper && photoWrapper.width && photoWrapper.height && getActiveIdPath()===idPath) {
      setCanvasConfig(prev => ({
        ...prev,
        width: Math.round(photoWrapper.width),
        height: Math.round(photoWrapper.height),
        scale: photoWrapper.width / prev.naturalWidth,
      }))
      updateCachedPhotoData(idPath, { currentSize: {
        width: Math.round(photoWrapper.width),
        height: Math.round(photoWrapper.height),
      }})
    }
  }, [photoWrapper])

  // 若編輯區或視窗改變寬度時，就重置translate
  // 目的是防止照片跟著放大或縮小超過限制尺寸
  useEffect(() => {
    if (editorSize.width && (editorSize.width !== editorSize.prevWidth)) {
      setTranslate({ startX: 0, startY: 0, x: 0, y: 0, scale: 1.0 })
      // updateCachedPhotoData(idPath, { translate: { x: 0, y: 0, scale: 1.0 }})
    }
  }, [editorSize.width])

  // 若點擊"還原預設大小"，就重置translate
  useEffect(() => {
    if (getActiveIdPath()===idPath && resetZoom) {
      setTranslate({ startX: 0, startY: 0, x: 0, y: 0, scale: 1.0 })
      updateCachedPhotoData(idPath, { resetZoom: false})
    }
  }, [resetZoom])


  const initLoadPhoto = async () => {
    addLog(`[${photoId}] 照片載入中...`)
  
    // const response = await getPhotoDataApi(folderId, photoId)
    const photoData = collData[folderId].photos[photoId]
    // if (response.status === 'success') {
    if (photoData) {
      // initCachedPhoto(`${folderId}/${photoId}`, response.data)
      initCachedPhoto(`${folderId}/${photoId}`, photoData)
    }
    // if (response.status === 'fail') {
    if (!photoData) {
      addLog(`[${photoId}] 照片載入失敗！`)
      clearCachedPhoto(folderId, photoId)
    }
  }

  useEffect(() => {
    if (loading) initLoadPhoto()
  }, [loading])

  const handlePhotoOnLoad = e => {
    if (!loaded) {
      WebFont.load({
        google: {
          families: ['Noto Sans Mono:900']
        },
        active: function () {
          setCanvasConfig({
            naturalWidth: photoRef.current.naturalWidth,
            naturalHeight: photoRef.current.naturalHeight,
            scale: photoRef.current.width / photoRef.current.naturalWidth,
            width: photoRef.current.width,
            height: photoRef.current.height,
          })
          updateCachedPhotoData(idPath, { currentSize: {
            width: photoRef.current.width,
            height: photoRef.current.height,
          }})
          photoRef.current.style.height = 'auto'
          // 如果照片資料沒有尺寸就寫入
          checkPhotoSize(idPath, {
            width: photoRef.current.naturalWidth, 
            height: photoRef.current.naturalHeight
          })
          setLoading(false)
          addLog(`[${photoId}] 照片載入完成`)
          setLoaded(true)
        }
      })
    }
  }

  const handlePhotoOnError = () => {
    clearCachedPhoto(folderId, photoId)
    setLoading(false)
    addLog(`[${photoId}] 照片載入失敗！`)
    setFailed(true)
  }

  /*
  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [panning])

  useEffect(() => {
    document.addEventListener('wheel', handleWheel)
    return () => document.removeEventListener('wheel', handleWheel)
  }, [])
  */

  const handleMouseDown = e => {
    if (e.button===1) {
      // e.preventDefault()
      // e.stopPropagation()
      setPanning(true)
      photoWrapperRef.current.style.cursor = 'grabbing'
      setTranslate(prev => ({ ...prev,
        startX: e.clientX - prev.x,
        startY: e.clientY - prev.y,
      }))
      // setPos({
      //   x: e.clientX - translate.x,
      //   y: e.clientY - translate.y,
      // })
    }
  }

  const handleMouseMove = e => {
    if (panning) {
      // e.preventDefault()
      // e.stopPropagation()
      setTranslate(prev => ({ ...prev,
        x: e.clientX - prev.startX,
        y: e.clientY - prev.startY,
      }))
      // updateCachedPhotoData(idPath, { translate: {
      //   x: e.clientX - pos.x,
      //   y: e.clientY - pos.y,
      //   scale: translate.scale,
      // }})
    }
  }

  const handleMouseUp = e => {
    if (panning) {
      // e.preventDefault()
      // e.stopPropagation()
      setPanning(false)
      photoWrapperRef.current.style.cursor = 'default'
    }
  }

  const handleMouseOut = e => {
    if (panning) {
      // e.preventDefault()
      // e.stopPropagation()
      setPanning(false)
      photoWrapperRef.current.style.cursor = 'default'
    }
  }

  const handleWheel = e => {
    // const delta = -e.deltaY  
    const power = clamp((-e.deltaY / 100), -1, 1) // 滾輪deltaY是100，觸控板是1以上
    const ratio = Math.pow(1.2, power)
    const nextWidth = photoWrapper.width * ratio
    // console.log(power, ratio, nextWidth)

    if ((power < 0 && nextWidth > 300) || (power > 0 && nextWidth < 10000)) {
      setTranslate(prev => ({ ...prev,
        x: Math.round(prev.x + e.nativeEvent.offsetX * (1 - ratio)),
        y: Math.round(prev.y + e.nativeEvent.offsetY * (1 - ratio)),
        scale: prev.scale * ratio,
      }))
    }
    /*
    if (delta > 0) {
      if (canvasConfig.width * ratio < 10000) {
        setTranslate(prev => ({ ...prev,
          x: Math.round(prev.x + e.nativeEvent.offsetX * (1 - ratio)),
          y: Math.round(prev.y + e.nativeEvent.offsetY * (1 - ratio)),
          scale: prev.scale * ratio,
        }))
        // updateCachedPhotoData(idPath, { translate: {
        //   x: Math.round(translate.x + e.nativeEvent.offsetX * (1 - ratio)),
        //   y: Math.round(translate.y + e.nativeEvent.offsetY * (1 - ratio)),
        //   scale: translate.scale * ratio,
        // }})
      }
    } else if (delta < 0) {
      if (canvasConfig.width * ratio > 300) {
        setTranslate(prev => ({ ...prev,
          x: Math.round(prev.x + e.nativeEvent.offsetX * (1 - 1 / ratio)),
          y: Math.round(prev.y + e.nativeEvent.offsetY * (1 - 1 / ratio)),
          scale: prev.scale / ratio,
        }))
        // updateCachedPhotoData(idPath, { translate: {
        //   x: Math.round(translate.x + e.nativeEvent.offsetX * (1 - 1 / ratio)),
        //   y: Math.round(translate.y + e.nativeEvent.offsetY * (1 - 1 / ratio)),
        //   scale: translate.scale / ratio,
        // }})
      }
    }
    */
  }

  return (
    <div 
      ref={photoWrapperRef}
      className={styles.canvasWrapper} 
      style={{
        width: `calc(100% * ${translate.scale})`,
        display: getActiveIdPath() === idPath ? 'block' : 'none',
        pointerEvents: getActiveIdPath() === idPath ? 'all' : 'none',
        cursor: 'default',
        position: 'relative',
        transformOrigin: `0px 0px`,
        transform: `translate(${translate.x}px, ${translate.y}px)`,
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseOut={handleMouseOut}
      onWheel={handleWheel}
    >
      {loading ?
        <div
          className="position-absolute d-flex justify-content-center align-items-center"
          style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', opacity: 0.7 }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        : ''}
      {photoUrl && 
        <div>
          <img ref={photoRef} src={photoUrl} onLoad={handlePhotoOnLoad} onError={handlePhotoOnError} alt="" />
        </div>}
      {(photoUrl && !loading && !failed) && <>
        <TargetRegions config={canvasConfig} regions={targetRegions} activeRegionIds={activeRegionIds} hovering={hovering} />
        <PredictRegions config={canvasConfig} regions={predictRegions} />
        <Canvas
          config={canvasConfig}
          active={getActiveIdPath()===idPath && userBehaviorMode===CONS.BEHAVIOR_MODE_EDIT}
          idPath={idPath}
          activeRegions={activeRegions}
          hovering={hovering}
          panning={panning}
        />
      </>}
    </div>
  )
}


const TargetRegions = props => {
  const userBehaviorMode = useStore(state => state.userBehaviorMode)
  const { config, regions, activeRegionIds, hovering } = props
  const ref = useRef(null)

  useEffect(() => {
    if (regions) {
      const ctx = ref.current.getContext('2d')
      ctx.clearRect(0, 0, ref.current.width, ref.current.height)
      regions.map((region) => activeRegionIds.indexOf(region.id)===-1 && drawRegion(ctx, config.scale, region, 'white', 'bottom'))
      regions.filter(region => activeRegionIds.indexOf(region.id)!==-1).map(region => {
        const isHovering = hovering ? hovering.id === region.id : null
        if(userBehaviorMode===CONS.BEHAVIOR_MODE_EDIT) {
          drawRegion(ctx, config.scale, region, 'rgba(255, 255, 0, 1)', 'bottom', isHovering && hovering.part==='body')
        } else {
          drawRegion(ctx, config.scale, region, 'rgba(0, 255, 0, 1)', 'bottom', isHovering && hovering.part==='body')
        }
      })
    }
  }, [regions, config.scale])

  return (
    <canvas 
      ref={ref}
      width={config.width} height={config.height}
    />
  )
}

const PredictRegions = props => {
  const showPredictResults = useStore(state => state.showPredictResults)
  const { config, regions } = props
  const ref = useRef(null)

  useEffect(() => {
    const ctx = ref.current.getContext('2d')
    ctx.clearRect(0, 0, ref.current.width, ref.current.height)
    if(regions) {
      regions.map((region) => 
        drawRegion(ctx, config.scale, region, 'cyan', 'top'))
    }
  }, [regions, config.scale])

  return (
    <canvas 
      ref={ref} 
      width={config.width} height={config.height}
      style={{ display: showPredictResults ? 'block' : 'none' }}
    />
  )
}