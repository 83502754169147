import React, { forwardRef, useState, useImperativeHandle, useEffect } from "react"
import { renamePhotoApi } from '@scripts/main.js'

import useStore from '@stores'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import validators from '../validators'

export default forwardRef((props, ref) => {
  const collData = useStore(state => state.collData)
  const photosByFolder = useStore(state => state.photosByFolder)
  const addLog = useStore(state => state.addLog)
  const activeIds = useStore(state => state.activeIds)
  const renameCachedPhoto = useStore(state => state.renameCachedPhoto)
  const folderIds = photosByFolder.map(f => f.id)
  const [show, setShow] = useState(false)
  const [doubleCheckShow, setDoubleCheckShow] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [value, setValue] = useState({ folderId: '', photoId: '' })
  const [newName, setNewName] = useState('')
  const [messages, setMessages] = useState([])

  useImperativeHandle(ref, () => ({
    setShow(bol) {
      setShow(bol)
    }
  }))

  useEffect(() => {
    if (show) {
      setValue({
        folderId: activeIds.folder || '',
        photoId: activeIds.photo || '',
      })
    } else {
      reset()
      setMessages([])
    }
  }, [show])

  const reset = () => {
    setValue({ folderId: '', photoId: '' })
    setNewName('')
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    addLog(`[${value.photoId}] 照片更名中...`)
    
    if (!collData[value.folderId].photos[newName]) {
      const photoData = collData[value.folderId].photos[value.photoId]
      const response = await renamePhotoApi(value.folderId, value.photoId, newName, photoData)
      if(response.status==='success') {
        const newPhotoUrls = response.data
        renameCachedPhoto(value.folderId, value.photoId, newName, newPhotoUrls)
        reset()
        // setShowSuccess(true)
        setShow(false)
        addLog(`[${value.photoId}] 照片更名完成`)
      }
      if(response.status==='fail') {
        setMessages(response.messages)
        addLog(`[${value.photoId}] 照片更名失敗！`)
      }
    } else {
      setMessages([{ type: 'error', content: `檔案名稱已存在，請變更名稱` }])
      addLog(`[${value.photoId}] 照片更名失敗！`)
    }
    setSubmitting(false)
    setDoubleCheckShow(false)
  }

  const handleOnChange = (e, param) => {
    const value = e.target.value
    if(param === 'folderId') {
      setValue({
        folderId: value,
        photoId: ''
      })
    }
    setValue(prev => ({ ...prev, [param]: value }))
  }

  const handleNewNameOnChange = e => {
    const value = e.target.value.trim()
    setNewName(value)

    let newMessages = []

    const rules = ['required', 'fileNameFormat']
    rules.map(r => {
      const response = validators[r](value)
      if(!response.passed) newMessages.push(...response.messages)
    })

    setMessages(newMessages)
  }

  return (
    <>
      <Modal 
        show={show} 
        onHide={()=>setShow(false)}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        backdrop={!submitting ? true : 'static'}>
        <Modal.Header closeButton={!submitting}>
          <Modal.Title as="h5">更名照片</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={e => { e.preventDefault(); }}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>選取目錄</Form.Label>
              <Form.Select size="sm" value={value.folderId} onChange={e => handleOnChange(e, 'folderId')} disabled={submitting}>
                <option value=''>未選取</option>
                {photosByFolder.map((f, i) => <option key={i} value={f.id}>{f.id}</option>)}
              </Form.Select>
            </Form.Group>
            
            {folderIds.indexOf(value.folderId) !==-1 ?
              <>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>選取檔案</Form.Label>
                  <Form.Select size="sm" value={value.photoId} onChange={e => handleOnChange(e, 'photoId')} disabled={submitting}>
                    <option value=''>未選取</option>
                    {photosByFolder[folderIds.indexOf(value.folderId)].photos.map((p, i) => <option key={i} value={p}>{p}</option>)}
                  </Form.Select>
                </Form.Group>

                {value.photoId!=='' ?
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>新名稱</Form.Label>
                    <Form.Control 
                      type="text" 
                      size="sm"
                      placeholder="請輸入照片名稱" 
                      value={newName}
                      onChange={handleNewNameOnChange} 
                      disabled={submitting} />
                    {messages.map((m, i) => 
                      <Form.Text key={i} className="text-danger">{m.content}</Form.Text>
                    )}
                  </Form.Group> : ''}
              </> : ''}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            disabled={value.folderId==='' || value.photoId===''|| newName==='' || submitting || messages.filter(m => m.type==='error').length!==0} 
            variant="secondary" size="sm" onClick={() => setDoubleCheckShow(true)}>
            更名
          </Button>
        </Modal.Footer>
      </Modal>
    
      <Modal
        show={doubleCheckShow} 
        onHide={()=>setDoubleCheckShow(false)}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        // size="sm"
        backdrop={!submitting ? true : 'static'}
        backdropClassName="z-index-higher"
        className="z-index-higher"
      >
        <Modal.Header closeButton={!submitting}>
          <Modal.Title as="h5">確定執行更名？</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          照片「{value.photoId}」將更名成「{newName}」。
        </Modal.Body>
        <Modal.Footer>
          <Button 
            disabled={submitting} 
            variant="normal" size="sm" onClick={()=>setDoubleCheckShow(false)}>
            取消
          </Button>
          <Button 
            disabled={submitting} 
            variant="secondary" size="sm" onClick={handleSubmit}>
            更名{submitting ? '中...' : ''}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
})