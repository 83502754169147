import React, { useState, useEffect } from 'react'
import styles from './main.module.scss'
import cx from 'classnames'

const Resizer = props => {
  const { type='vertical', refBefore, refAfter, beforeMin=200, afterMin=200 } = props
  const [isDragging, setIsDragging] = useState(false)
  const [startPos, setStartPos] = useState(null)
  const [startBefore, setStartBefore] = useState(null)
  const [startAfter, setStartAfter] = useState(null)

  const handleMouseDown = e => {
    if (e.button===0) {
      e.preventDefault()
      e.stopPropagation()
      setIsDragging(true)
    
      switch(type) {
        case 'horizontal':
          setStartPos(e.clientY)
          setStartBefore(refBefore.current.offsetHeight)
          setStartAfter(refAfter.current.offsetHeight)
          break
        case 'vertical':
        default:
          setStartPos(e.clientX)
          setStartBefore(refBefore.current.offsetWidth)
          setStartAfter(refAfter.current.offsetWidth)
          break
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseOver)
    document.addEventListener('mouseup', handleMouseUp)
    return () => {
      document.removeEventListener('mousemove', handleMouseOver)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging])

  const handleMouseOver = e => {
    e.preventDefault()
    e.stopPropagation()
    if(isDragging) {
      switch(type) {
        case 'horizontal':
          const nextBeforeHeight = (startBefore + (e.clientY - startPos))
          const nextAfterHeight = (startAfter - (e.clientY - startPos))
          if(nextBeforeHeight > beforeMin && nextAfterHeight > afterMin) {
            refBefore.current.style.height = nextBeforeHeight + 'px'
            refAfter.current.style.height = nextAfterHeight + 'px'
          }
          break
        case 'vertical':
        default:
          const nextBeforeWidth = (startBefore + (e.clientX - startPos))
          const nextAfterWidth = (startAfter - (e.clientX - startPos))
          if(nextBeforeWidth > beforeMin && nextAfterWidth > afterMin) {
            refBefore.current.style.width = nextBeforeWidth + 'px'
            refAfter.current.style.width = nextAfterWidth + 'px'
          }
          break
      }
    }
  }

  const handleMouseUp = e => {
    if (e.button===0) {
      e.preventDefault()
      e.stopPropagation()
      setIsDragging(false)
    }
  }

  return (
    <div className={cx(styles.body, styles[type])}>
      <div 
        className={styles.resizer} 
        onMouseDown={handleMouseDown}
      />
    </div>
  )
}

export default Resizer