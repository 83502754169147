import React, { forwardRef, useState, useImperativeHandle, useEffect } from "react"
import { getUserListApi, updateUserRoleApi } from '@scripts/main.js'

import useStore from '@stores'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ListGroup from 'react-bootstrap/ListGroup'

export default forwardRef((props, ref) => {
  const user = useStore(state => state.user)
  const addLog = useStore(state => state.addLog)
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [users, setUsers] = useState([])
  const [originalUsers, setOriginalUsers] = useState([])

  useImperativeHandle(ref, () => ({
    setShow(bol) {
      setShow(bol)
    }
  }))

  useEffect(() => {
    if (show) {
      const fetchUsers = async () => {
        const response = await getUserListApi()
        if(response.status==='success') {
          setUsers(response.data)
          setOriginalUsers(response.data.map(d => d.role))
        }
        setLoading(false)
      }
      fetchUsers()
    } else {
      setLoading(true)
    }
  }, [show])

  const handleSubmit = async () => {
    setSubmitting(true)

    let everFail = false, shouldReload = false
    await Promise.all(users.filter((u, i) => u.role!==originalUsers[i]).map(async u => {
      addLog(`[${u.email}] 權限更新中...`)
      const response = await updateUserRoleApi(u.uid, u.role)
      if(response.status==='success') {
        addLog(`[${u.email}] 權限更新完成`)
        // everSuccess = true
        setOriginalUsers(users.map(d => d.role))
        
        if(user.uid===u.uid) shouldReload = true
      }
      if(response.status==='fail') {
        everFail = true
        addLog(`[${u.email}] 權限更新失敗！`)
      }
    }))
    if(shouldReload) window.location.reload()
    if(!everFail) setShow(false)

    setSubmitting(false) 
  }

  const handleOnChange = (e, index) => {
    setUsers(users.map((u, i) => index===i ? ({ ...u, role: u.role==='admin' ? 'editor' : 'admin' }) : u ))
  }

  return (
    <Modal 
      show={show} 
      onHide={()=>setShow(false)}
      animation={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      backdrop={!submitting ? true : 'static'}>
      <Modal.Header closeButton={!submitting}>
        <Modal.Title as="h5">Admin 帳號設定</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={e => { e.preventDefault(); }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <ListGroup style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {users.map((user, i) =>
                <ListGroup.Item key={`user-${i}`} style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Check 
                    type="switch"
                    checked={user.role==='admin'}
                    onChange={e => handleOnChange(e, i)}
                  />
                  {user.email}
                </ListGroup.Item>
              )}
            </ListGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          disabled={loading || JSON.stringify(users.map(u => u.role))===JSON.stringify(originalUsers) || submitting} 
          variant="secondary" size="sm" onClick={handleSubmit}>
          儲存{submitting ? '中...' : ''}
        </Button>
      </Modal.Footer>
    </Modal>
  )
})