import React from 'react'
import './App.css'
import './Bootstrap.scss'
import { AuthProvider } from '../contexts/auth'
import FirebaseAuth from '../components/HOC/FirebaseAuth'
import Dashboard from '../pages/dashboard/index'
import SignIn from '../pages/sign-in'
import useStore from '@stores'

const App = () =>
  <AuthProvider>
    <FirebaseAuth>
      <div className="App">
        <Body />
      </div>
    </FirebaseAuth>
  </AuthProvider>

const Body = () => {
  const user = useStore(state => state.user)
  const authChecked = useStore(state => state.authChecked)

  return authChecked ? (
    user ?
    <Dashboard /> :
    <SignIn />
  ) : <></>
}

export default App
