import firebase from 'firebase'


const config = {
  apiKey: 'AIzaSyCC_QkcAtRrNxc4DmRn45SlLxloMn0gMTg',
  appId: "1:875718502583:web:d20e8ee12a0aac9fc04532",
  storageBucket: "photo-id-app.appspot.com",
  authDomain: 'photo-id-app.firebaseapp.com',
  projectId: 'photo-id-app',
}
/*
const config = {
  apiKey: "AIzaSyAol5XFXN2uBDcwJnrMl0DReXTKZeZBuBU",
  appId: "1:965886274229:web:192996b5a27747331e2cfc",
  storageBucket: "photo-id-app-ai.appspot.com",
  authDomain: "photo-id-app-ai.firebaseapp.com",
  projectId: "photo-id-app-ai",
}
*/
const firebaseApp = firebase.initializeApp(config)

export default firebaseApp